import { MenuEntry } from '@olive-libs/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('BSC'),
    icon: 'ChainBSC',
    items: [
      {
        label: t('Avalanche'),
        href: 'https://avax.olive.cash',
      },
      {
        label: t('Fantom'),
        href: 'https://fantom.olive.cash',
      },
      {
        label: t('Polygon'),
        href: 'https://polygon.olive.cash',
      },
    ],
  },
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Trade'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0x617724974218A18769020A70162165A539c07E8a',
      },
      {
        label: t('Liquidity'),
        href: '/pool',
      },
      {
        label: t('LP Migration'),
        href: 'https://v1exchange.pancakeswap.finance/#/migrate',
      },
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: '/prediction',
  // },
  // {
  //   label: t('Lottery'),
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  {
    label: t('Collectibles'),
    icon: 'NftIcon',
    href: '/collectibles',
  },
  {
    label: t('Listings'),
    icon: 'InfoIcon',
    items: [
      {
        label: t('Binance'),
        href: 'https://www.binance.com/en/price/olive-cash',
      },
      {
        label: t('Coinmarketcap'),
        href: 'https://coinmarketcap.com/currencies/olive-cash/',
      },
      {
        label: t('CoinGecko'),
        href: 'https://www.coingecko.com/en/coins/olive-cash',
      },
      {
        label: t('Defi Llama'),
        href: 'https://defillama.com/protocol/olive-cash',
      },
      {
        label: t('DigitalCoinPrice'),
        href: 'https://digitalcoinprice.com/coins/olive-cash',
      },
      {
        label: t('ICO Holder'),
        href: 'https://icoholder.com/en/olivecash-1002566',
      },
      {
        label: t('WalletNow'),
        href: 'https://walletnow.net/account/0x617724974218a18769020a70162165a539c07e8a',
      },
      {
        label: t('WhatToFarm'),
        href: 'https://whattofarm.io/?q=Olive',
      },
      {
        label: t('YieldMagic'),
        href: 'https://farm.yieldmagic.net/olive.cash',
      },
      {
        label: t('PancakeSwap Info'),
        href: 'https://pancakeswap.info/token/0x617724974218A18769020A70162165A539c07E8a',
      },
      {
        label: t('Dex Guru'),
        href: 'https://dex.guru/token/0x617724974218a18769020a70162165a539c07e8a-bsc',
      },
      {
        label: t('BSC Scan'),
        href: 'https://www.bscscan.com/address/0x617724974218A18769020A70162165A539c07E8a',
      },
      {
        label: t('Dapp Radar'),
        href: 'https://dappradar.com/binance-smart-chain/defi/olive-cash',
      },
      {
        label: t('Nomics'),
        href: 'https://nomics.com/assets/olive2-olivecash-token',
      },
      {
        label: t('Coinbase'),
        href: 'https://www.coinbase.com/price/olive-cash',
      },
      {
        label: t('Coincarp'),
        href: 'https://www.coincarp.com/currencies/OliveCash/',
      }
    ],
  },
  // {
  //   label: t('Team Battle'),
  //   icon: 'TeamBattleIcon',
  //   href: '/competition',
  // },
  // {
  //   label: t('Teams & Profile'),
  //   icon: 'GroupsIcon',
  //   items: [
  //     {
  //       label: t('Leaderboard'),
  //       href: '/teams',
  //     },
  //     {
  //       label: t('Task Center'),
  //       href: '/profile/tasks',
  //     },
  //     {
  //       label: t('Your Profile'),
  //       href: '/profile',
  //     },
  //   ],
  // },
  // {
  //   label: t('Info'),
  //   icon: 'InfoIcon',
  //   href: 'https://pancakeswap.info',
  // },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('V1 site'),
        href: 'https://old-bsc.olive.cash',
      },
      {
        label: t('Github'),
        href: 'https://github.com/olive-cash',
      },
      {
        label: t('Docs'),
        href: 'https://olive-cash.gitbook.io/olive-cash/',
      },
      {
        label: t('Blog'),
        href: 'https://olive-cash.medium.com',
      }
    ],
  },
]

export default config
