import { ChainId, Token } from '@pancakeswap/sdk'

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'PancakeSwap Token',
  ),
}
export const BUSD: { [chainId: number]: Token } = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)
export const USDC = new Token(
  ChainId.MAINNET,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
)

const tokens = {
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x617724974218A18769020A70162165A539c07E8a',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  olive: {
    symbol: 'OLIVE',
    address: {
      56: '0x617724974218A18769020A70162165A539c07E8a',
    },
    decimals: 18,
    projectLink: 'https://olive.cash/',
  },
  spore: {
    symbol: 'SPORE',
    address: {
      56: '0x33A3d962955A3862C8093D1273344719f03cA17C',
    },
    decimals: 9,
    projectLink: 'https://sporefinance.co/',
  },
  doge: {
    symbol: 'DOGE',
    address: {
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    decimals: 8,
    projectLink: 'https://dogecoin.com/',
  },
  aquagoat: {
    symbol: 'AQUAGOAT',
    address: {
      56: '0x07af67b392B7A202fAD8E0FBc64C34F33102165B',
    },
    decimals: 9,
    projectLink: 'https://www.aquagoat.finance/',
  },
  unsafemoon: {
    symbol: 'UNSAFEMOON',
    address: {
      56: '0x8740ae96e6cb91eaea2b1ba61c347792e308ebf2',
    },
    decimals: 9,
    projectLink: 'https://www.unsafemoon.com/',
  },
  com: {
    symbol: 'COM',
    address: {
      56: '0x7fa892544d49598460b821de4d99e8c28b1decaa',
    },
    decimals: 18,
    projectLink: 'https://complus.exchange/',
  },
  zero: {
    symbol: 'ZERO',
    address: {
      56: '0x1f534d2B1ee2933f1fdF8e4b63A44b2249d77EAf',
    },
    decimals: 18,
    projectLink: 'https://www.0.exchange/',
  },
  fg: {
    symbol: 'FG',
    address: {
      56: '0x4492ca0aff6d603e18aea5075b49a5ff76b9ea06',
    },
    decimals: 9,
    projectLink: 'https://farmageddon-token.com/',
  },
  leopard: {
    symbol: 'LEOPARD',
    address: {
      56: '0x4Efab39b14167Da54aebed2094a61aA1FD384056',
    },
    decimals: 9,
    projectLink: 'https://leopardbsc.org',
  },
  gapt: {
    symbol: 'GAPT',
    address: {
      56: '0x24c5aaa98ed11594a4ebb69e065790c716c39e10',
    },
    decimals: 9,
    projectLink: 'https://gapt.io',
  },
  moonrabbit: {
    symbol: 'MOONRABBIT',
    address: {
      56: '0x5Ac69C40bc7363EA7D98574C78F4f54DbecbD54B',
    },
    decimals: 9,
    projectLink: 'https://moonrabbit.money/',
  },
  bunny: {
    symbol: 'BUNNY',
    address: {
      56: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    },
    decimals: 18,
    projectLink: 'https://pancakebunny.finance/',
  },
  mkc: {
    symbol: 'MKC',
    address: {
      56: '0x92cc2c999aDE41C71D1c8136D5b57d12564E045f',
    },
    decimals: 18,
    projectLink: 'https://www.milkyway.games/',
  },
  roobot: {
    symbol: 'ROOBOT',
    address: {
      56: '0x1D34853beA8F455D5a6908BA1D6d40AbcA6c2474',
    },
    decimals: 9,
    projectLink: 'https://mrroobot.com/',
  },
  watermelon: {
    symbol: 'WATERMELON',
    address: {
      56: '0x00',
    },
    decimals: 0,
    projectLink: 'https://watermelonswap.net/',
  },
  bubblegum: {
    symbol: 'BUBBLEGUM',
    address: {
      56: '0x58bfc98f3974aef4fed9a0168b97cccd3512cb4a',
    },
    decimals: 9,
    projectLink: 'https://www.bubblegum.life/',
  },
  brigadeiro: {
    symbol: 'BRIGADEIRO',
    address: {
      56: '0x5b69eabf1c748f590f60906d964158dba0f53285',
    },
    decimals: 9,
    projectLink: 'https://brigadeiro.finance/',
  },
  lpup: {
    symbol: 'LPUP',
    address: {
      56: '0x7a00d94553bf51f955ec804b0de8d242048d666b',
    },
    decimals: 18,
    projectLink: 'https://www.lightningpup.co/',
  },
  beryl: {
    symbol: 'BERYL',
    address: {
      56: '0x84d38874a104110b162b6aa817816a3d3dcb3366',
    },
    decimals: 9,
    projectLink: 'https://beryldefi.com/',
  },
  teamtrees: {
    symbol: 'TEAMTREES',
    address: {
      56: '0xe1f8c6ce0d69cbeb67c6bc3685e52c96291394a8',
    },
    decimals: 9,
    projectLink: 'https://www.teamtreestoken.org/',
  },
  unft: {
    symbol: 'UNFT',
    address: {
      56: '0xdb29192fc2b487bb5185e155752328d4f249743c',
    },
    decimals: 9,
    projectLink: 'https://ultranft.finance/',
  },
  wspp: {
    symbol: 'WSPP',
    address: {
      56: '0x46d502fac9aea7c5bc7b13c8ec9d02378c33d36f',
    },
    decimals: 0,
    projectLink: 'https://wolfsafepoorpeople.com/',
  },
  ltrbt: {
    symbol: 'LTRBT',
    address: {
      56: '0x17D749D3E2ac204a07e19D8096d9a05c423ea3af',
    },
    decimals: 9,
    projectLink: 'http://newlittlerabbit.net/',
  },
  charix: {
    symbol: 'CHARIX',
    address: {
      56: '0x4eabb20064586dea07aaaf84d4ba35037854bd44',
    },
    decimals: 9,
    projectLink: 'https://charix.org/',
  },
  truck: {
    symbol: 'TRUCK',
    address: {
      56: '0xc78475296554ee513b1abb8a7b3a4a14dae5d50a',
    },
    decimals: 18,
    projectLink: 'https://truckdrivertoken.com/',
  },
  touranus: {
    symbol: 'ToUranus',
    address: {
      56: '0xd1d59834b9688af4906aef67e13ca9dad1c22830',
    },
    decimals: 9,
    projectLink: 'https://touranustoken.com/',
  },
  aquashib: {
    symbol: 'AQUASHIB',
    address: {
      56: '0xcF654A685e6415a026A6B1C530d49eBa8869f8a4',
    },
    decimals: 9,
    projectLink: 'https://www.aquashiba.io/',
  },
  coldkoala: {
    symbol: 'ColdKoala',
    address: {
      56: '0x1bfE24e7Fb1d3B2dfFD9C1d49372b07bC6fDa829',
    },
    decimals: 9,
    projectLink: 'https://www.coldkoala.com/',
  },
  inko: {
    symbol: 'InuNeko',
    address: {
      56: '0xac543b0de08e8396b4df878355f504c203ac1313',
    },
    decimals: 9,
    projectLink: 'https://inuneko.io/',
  },
  nmx: {
    symbol: 'NMX',
    address: {
      56: '0xd32d01a43c869edcd1117c640fbdcfcfd97d9d65',
    },
    decimals: 18,
    projectLink: 'https://nominex.io/?r=99717',
  },
  gap: {
    symbol: 'GAP',
    address: {
      56: '0x62613F4b4F7600C7C0F16c97Aefc29c8A2CA524F',
    },
    decimals: 9,
    projectLink: 'https://globaladversity.org/',
  },
  floki: {
    symbol: 'FLOKI',
    address: {
      56: '0x330540a9d998442dcbc396165D3dDc5052077bB1',
    },
    decimals: 9,
    projectLink: 'https://www.shibafloki.com/',
  },
  flokiMigrated: {
    symbol: 'FLOKI',
    address: {
      56: '0x4AadaD81487c3fadD9F162b851E6a61b729200cb',
    },
    decimals: 18,
    projectLink: 'https://www.shibafloki.com/',
  },
  sensi: {
    symbol: 'SENSI',
    address: {
      56: '0x7869044d36ea75975b793ca4312608cc3817895b',
    },
    decimals: 9,
    projectLink: 'https://sensible.finance/',
  },
  wyvern: {
    symbol: 'WYVERN',
    address: {
      56: '0x470862af0cf8d27ebfe0ff77b0649779c29186db',
    },
    decimals: 9,
    projectLink: 'https://wyverntoken.com/',
  },
  glxc: {
    symbol: 'GLXC',
    address: {
      56: '0x37a9357b0d575d419595cb56974fb89729e737de',
    },
    decimals: 18,
    projectLink: 'https://glxcoin.space/',
  },
  gwspp: {
    symbol: 'GWSPP',
    address: {
      56: '0xcccbf2248ef3bd8d475ea5de8cb06e19f4591a8e',
    },
    decimals: 6,
    projectLink: 'https://wolfsafepoorpeople.com/golden/',
  },
  disc: {
    symbol: 'DISC',
    address: {
      56: '0x2bcdcf1ef1320fc5f9a71ca0bd1504d6125f7569',
    },
    decimals: 18,
    projectLink: 'https://discas.vision',
  },
  gmpx: {
    symbol: 'GMPX',
    address: {
      56: '0x221fd3faffd3ccab2369ffd6a24c7845674787c6',
    },
    decimals: 9,
    projectLink: 'https://www.galaxiumphoenix.com/',
  },
  tigz: {
    symbol: 'TigZ',
    address: {
      56: '0x535874bfbecac5f235717faea7c26d01c67b38c5',
    },
    decimals: 18,
    projectLink: 'https://czodiac.com/',
  },
  laika: {
    symbol: 'LAIKA',
    address: {
      56: '0x2a49De60cc6204c3AFC3e770FDB30A0554147519',
    },
    decimals: 9,
    projectLink: 'https://laikato.space/',
  },
  blast: {
    symbol: 'BLAST',
    address: {
      56: '0x09044dcfbe25225c0155c793596070c1fa584015',
    },
    decimals: 9,
    projectLink: 'https://www.blastoff.finance/',
  },
  eggc: {
    symbol: 'EGGC',
    address: {
      56: '0xa244ef3f07699b4b929c17e99ddb39bec238465b',
    },
    decimals: 18,
    projectLink: 'https://eggchain.netlify.app',
  },
  roto: {
    symbol: 'ROTO',
    address: {
      56: '0xa9b21900115843d655c4a8a5cb7adf49e8f4113a',
    },
    decimals: 9,
    projectLink: 'https://rotomoon.app/',
  },
  safebond: {
    symbol: 'SAFEBOND',
    address: {
      56: '0x97e184e8db85721cdcc99ea8aecf49a2ebedc148',
    },
    decimals: 18,
    projectLink: 'https://safebond.info/',
  },
  poc: {
    symbol: 'POC',
    address: {
      56: '0x123f92226c626adc919ad122d6cc3c20a6c25666',
    },
    decimals: 9,
    projectLink: 'https://pangeaoceancleanup.com/',
  },
  shiko: {
    symbol: 'SHIKO',
    address: {
      56: '0xb6d053e260d410eac02ea28755696f90a8ecca2b',
    },
    decimals: 9,
    projectLink: 'https://shikoku.exchange/',
  },
  cmon: {
    symbol: 'CMON',
    address: {
      56: '0x4Bd658D22f950637993e30681644e66e2d361208',
    },
    decimals: 9,
    projectLink: 'https://www.clearmoon.io/',
  },
  gdoge: {
    symbol: 'GDOGE',
    address: {
      56: '0xA53E61578fF54f1ad70186Be99332a6e20b6ffa9',
    },
    decimals: 9,
    projectLink: 'https://goldendoge.finance/',
  },
  zar: {
    symbol: 'ZAR',
    address: {
      56: '0x310e6b2faf62380956a90b84b7f5d84e7dccafd7',
    },
    decimals: 9,
    projectLink: 'https://www.zmartcoin.net/',
  },
  hup: {
    symbol: 'HUP',
    address: {
      56: '0x375A7B6A6F74a5Acb86C420c53C4Ce8cf1d51b4D',
    },
    decimals: 9,
    projectLink: 'https://www.hup.life/',
  },
  cdoge: {
    symbol: 'CDOGE',
    address: {
      56: '0x3e2301cdb1359647bbaa885bfeda0137d1c29eb8',
    },
    decimals: 18,
    projectLink: 'https://twitter.com/chocodogebsc',
  },
  husky: {
    symbol: 'HUSKY',
    address: {
      56: '0x52d88a9a2a20a840d7a336d21e427e9ad093deea',
      43114: '0x65378b697853568dA9ff8EaB60C13E1Ee9f4a654',
    },
    decimals: 18,
    projectLink: 'https://www.husky.space/',
  },
  mgp: {
    symbol: 'MGP',
    address: {
      56: '0xdf77539d45abfe4aea13f1240ef82b991ee0f023',
    },
    decimals: 9,
    projectLink: 'https://www.microoo-gaming-protocol.org/',
  },
  kana: {
    symbol: 'KANA',
    address: {
      56: '0x0328a69b363a16f66810b23cb0b8d32abadb203d',
    },
    decimals: 18,
    projectLink: 'https://kanaloa.network',
  },
  viva: {
    symbol: 'VIVA',
    address: {
      56: '0xc18083cc09da5fb3e29ae39e186a817efce6cb3f',
    },
    decimals: 9,
    projectLink: 'https://vivaswap.io/',
  },
  bamboo: {
    symbol: 'BAMBOO',
    address: {
      56: '0x198abb2d13faa2e52e577d59209b5c23c20cd6b3',
    },
    decimals: 18,
    projectLink: 'https://www.bamboodefi.com/pages/home',
  },
  foxy: {
    symbol: 'FOXY',
    address: {
      56: '0xa3f22c6f69d21d2670795b956697ecb5b58d8526',
    },
    decimals: 18,
    projectLink: 'https://foxy-lab.io/',
  },
  shak: {
    symbol: 'SHAK',
    address: {
      56: '0x76e08e1c693d42551dd6ba7c2a659f74ff5ba261',
    },
    decimals: 18,
    projectLink: 'https://www.shakitainu.com/',
  },
  reddoge: {
    symbol: 'REDDOGE',
    address: {
      56: '0x45b41bf48eb20466737b4605393734e9165d4b98',
    },
    decimals: 9,
    projectLink: 'https://www.dogetoken.red/',
  },
  czf: {
    symbol: 'CZF',
    address: {
      56: '0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
    },
    decimals: 18,
    projectLink: 'https://app.czodiac.com/',
  },
  xmine: {
    symbol: 'XMINE',
    address: {
      56: '0x0fa9651a0ecc19906843c13c60443300b9d37355',
    },
    decimals: 9,
    projectLink: 'https://www.xmine.one/',
  },
  ftk: {
    symbol: 'FTK',
    address: {
      56: '0x41fb7f7703c7fcbfef42fbed0ef6963684616c5e',
    },
    decimals: 9,
    projectLink: 'https://tokenftk.cc/',
  },
  ldc: {
    symbol: 'LDC',
    address: {
      56: '0xbc537c876510083f7d2406c12fdd93cbe8da4b8a',
    },
    decimals: 18,
    projectLink: 'https://littledogecake.com',
  },
  supr: {
    symbol: 'SUPR',
    address: {
      56: '0xb6f5c624a59bc1f6f20dd9afd2166cf09e480ba6',
    },
    decimals: 18,
    projectLink: 'www.supremium.global',
  },
  godz: {
    symbol: 'GODZ',
    address: {
      56: '0xda4714fee90ad7de50bc185ccd06b175d23906c1',
    },
    decimals: 9,
    projectLink: 'https://godzillacoin.io/',
  },
  dut: {
    symbol: 'DUT',
    address: {
      56: '0x2cebeb83d8b71f39eb405ad97284c7c468c5431f',
    },
    decimals: 9,
    projectLink: 'https://www.dogsunitedtoken.com/',
  },
  fswap: {
    symbol: 'FSWAP',
    address: {
      56: '0xd22246644d2be5d0427a8e474477d96677c3ec24',
    },
    decimals: 18,
    projectLink: 'https://flatswap.exchange',
  },
  gem: {
    symbol: 'GEM',
    address: {
      56: '0xbac1df744df160877cdc45e13d0394c06bc388ff',
    },
    decimals: 18,
    projectLink: 'https://nftmall.io',
  },
  vdv: {
    symbol: 'VDV',
    address: {
      56: '0xe586a0af5f3844f1408fed1d284270827a116010',
    },
    decimals: 8,
    projectLink: 'https://vdvtoken.io',
  },
  aquagoat2: {
    symbol: 'AQUAGOAT',
    address: {
      56: '0x1606940bb5cd6de59a7e25367f4fb8965f38f122',
    },
    decimals: 9,
    projectLink: 'https://www.aquagoat.finance/',
  },
  flrs: {
    symbol: 'FLRS',
    address: {
      56: '0x43fffb14db56bfd6432e7acaadff697121861f96',
    },
    decimals: 9,
    projectLink: 'https://flourish-coin.com/',
  },
  dogedrinks: {
    symbol: 'DOGEDRINKS',
    address: {
      56: '0x82ed5fe7e64875c30fe7faaf2bf04e3ba9f8f066',
    },
    decimals: 9,
    projectLink: 'https://doge-drinks.com/',
  },
  okboomer: {
    symbol: 'OKBOOMER',
    address: {
      56: '0xE9db02A654b74ca04734B26ef3B2a79808d43404',
    },
    decimals: 9,
    projectLink: 'https://okboomertoken.com/',
  },
  doge0: {
    symbol: 'DogeZero',
    address: {
      56: '0xb93a1ea48f0a4fa0ff6080598d679375f9b4b1bd',
    },
    decimals: 18,
    projectLink: 'https://dogezero.net/',
  },
  if1: {
    symbol: 'IF1',
    address: {
      56: '0xfcac1a3ede7b55cc51e3ebff2885a67fbfe01a1a',
    },
    decimals: 9,
    projectLink: 'https://infiniteone.io/',
  },
  seachain: {
    symbol: 'SeaChain',
    address: {
      56: '0x36b24b2f78725495e858af9e72f7df69dade3dca',
    },
    decimals: 9,
    projectLink: 'https://www.seachaintoken.com/',
  },
  czusd: {
    symbol: 'CZUSD',
    address: {
      56: '0xe68b79e51bf826534ff37aa9cee71a3842ee9c70',
    },
    decimals: 18,
    projectLink: 'https://czodiac.com/',
  },
  fdls: {
    symbol: 'FDLS',
    address: {
      56: '0x85Cec9d09529C4239Dcd89018889238aBdD3EDE6',
    },
    decimals: 9,
    projectLink: 'https://fideliscrypto.tech/',
  },
  cff: {
    symbol: 'CFF',
    address: {
      56: '0xd7e1a99f1e0794195a532cb0cb641ddf51ddaf51',
    },
    decimals: 4,
    projectLink: 'https://coffe.io/',
  },
  game1: {
    symbol: 'GAME1',
    address: {
      56: '0x0e52d24c87a5ca4f37e3ee5e16ef5913fb0cceeb',
    },
    decimals: 18,
    projectLink: 'https://game1network.com/',
  },
  chy: {
    symbol: 'CHY',
    address: {
      56: '0x8F0697bE1D6AF7e3415D87d6BaD1073e5c6459D2',
    },
    decimals: 18,
    projectLink: 'https://greenhouseswap.finance/',
  },
  prho: {
    symbol: 'PRHO',
    address: {
      56: '0x84a4a0df19f80fe00c856c354f05062d281e1a92',
    },
    decimals: 18,
    projectLink: 'https://rhinobsc.com/',
  },
  cbr: {
    symbol: 'CBR',
    address: {
      56: '0xbc53ea3b031e61229ee73fafb2f9d4862f842eff',
    },
    decimals: 9,
    projectLink: 'https://www.captainbulldog.finance/',
  },
  antx: {
    symbol: 'ANTX',
    address: {
      56: '0x2f2265206ebb359bdd2b936ba44cfe9307d6807a',
    },
    decimals: 18,
    projectLink: 'https://www.antx.space/',
  },
  balpac: {
    symbol: 'BALPAC',
    address: {
      56: '0x83DD5794D844BB4bD630c2FAcC45A77729232991',
    },
    decimals: 18,
    projectLink: 'https://alpaca.baby/',
  },
  babyMoonFloki: {
    symbol: 'FLOKI',
    address: {
      56: '0x54e87ed5a096f09d9665fd114002bddfc2084a7f',
    },
    decimals: 9,
    projectLink: 'https://babymoonfloki.com/',
  },
  qua: {
    symbol: 'QUA',
    address: {
      56: '0x21ed4f0669872a55cab53c0d9de65c7e4a7ea2c3',
    },
    decimals: 9,
    projectLink: 'https://www.thequa.finance/',
  },
  ample: {
    symbol: 'AMPLE',
    address: {
      56: '0x335f6e0e804b70a96bf9eb8af31588942e9b2515',
    },
    decimals: 18,
    projectLink: 'https://ampleswap.com/farms',
  },
  cgu: {
    symbol: 'CGU',
    address: {
      56: '0x747d74db20cc422f39ab54edb2a3ce21f3c98af1',
    },
    decimals: 8,
    projectLink: 'https://www.cgu.io/',
  },
  fcf: {
    symbol: 'FCF',
    address: {
      56: '0x4673f018cc6d401AAD0402BdBf2abcBF43dd69F3',
    },
    decimals: 18,
    projectLink: 'https://frenchconnection.finance/',
  },
  lks: {
    symbol: 'LKS',
    address: {
      56: '0x30571bb45a52a1fa1a8edad7bece80bcbb824cc3',
    },
    decimals: 18,
    projectLink: 'https://luckystrike.club/',
  },
  crox: {
    symbol: 'CROX',
    address: {
      56: '0x2c094f5a7d1146bb93850f629501eb749f6ed491',
    },
    decimals: 18,
    projectLink: 'https://app.croxswap.com/',
  },
  buff: {
    symbol: 'BUFF',
    address: {
      56: '0x10a49f1fC8C604eA7f1c49bcc6ab2A8E58e77EA5',
    },
    decimals: 18,
    projectLink: 'https://www.buffaloswap.org/',
  },
  redbuff: {
    symbol: 'REDBUFF',
    address: {
      56: '0x9eC132c19f2c35272DE3f81a75D674752D952DA8',
    },
    decimals: 18,
    projectLink: 'https://red.buffaloswap.org/',
  },
  cht: {
    symbol: 'CHT',
    address: {
      56: '0x6a1afd7cc6db9f2de4af186d249c163bb9701f9c',
    },
    decimals: 4,
    projectLink: 'https://cryptoheroes.net/',
  },
  lory: {
    symbol: 'LORY',
    address: {
      56: '0xcd5d75dbe75449a9021b6c570a41959eb571c751',
    },
    decimals: 18,
    projectLink: 'https://app.yieldparrot.finance/',
  },
  frt: {
    symbol: 'FRT',
    address: {
      56: '0xd51237a6f3219d186f0c8d8dd957b1bcb3ce5d48',
    },
    decimals: 18,
    projectLink: 'https://farmageddon.farm/',
  },
  hero: {
    symbol: 'HERO',
    address: {
      56: '0x5d96991648d22935bdf6c7064b65c3c93f82aa61',
    },
    decimals: 18,
    projectLink: 'https://herofloki.com',
  },
  bbrb: {
    symbol: 'BBRB',
    address: {
      56: '0xd41dbb670b2ce44d22ac7adb0ac655d2e67fb1ad',
    },
    decimals: 18,
    projectLink: 'https://babyrabbit.io',
  },
  stkd: {
    symbol: 'STKD',
    address: {
      56: '0x578b2a12d2c661cd4abfb8e98670acd7b2323bc7',
    },
    decimals: 18,
    projectLink: 'https://www.stakdtoken.finance/',
  },
  sitx: {
    symbol: 'SITX',
    address: {
      56: '0xc8581870db5305b2c14588e7e58484086cc3ed4f',
    },
    decimals: 18,
    projectLink: 'https://sportinvesting.com/',
  },
  cgs: {
    symbol: 'CGS',
    address: {
      56: '0x26d88B1E61E22Da3f1A1BA95A1bA278f6FCef00b',
    },
    decimals: 18,
    projectLink: 'https://cougarswap.io',
  },
  shio: {
    symbol: 'SHIO',
    address: {
      56: '0x0c251e67d41efc33a9d3264a5492f63f743ca87c',
    },
    decimals: 9,
    projectLink: 'https://shibanomitoken.com',
  },
  coris: {
    symbol: 'CORIS',
    address: {
      56: '0x2a2cd8b1f69eb9dda5d703b3498d97080c2f194f',
    },
    decimals: 18,
    projectLink: 'https://corgiswap.org/',
  },
  rshib: {
    symbol: 'RSHIB',
    address: {
      56: '0xc7fba797141f5392094e3dca8943ca7d8b6f92d8',
    },
    decimals: 9,
    projectLink: 'https://robotshib.com/',
  },
  jizzd: {
    symbol: 'JIZZD',
    address: {
      56: '0xae3fe7bb963e9a3274061818ea54466e123b1772',
    },
    decimals: 9,
    projectLink: 'https://jizzdogecoin.com/#home',
  },
  squid: {
    symbol: 'SQUID',
    address: {
      56: '0xAE61e7dc989718E700C046a2483e93513eDCA484',
    },
    decimals: 18,
    projectLink: 'https://squidstake.com/',
  },
  gut: {
    symbol: 'GUT',
    address: {
      56: '0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
    },
    decimals: 18,
    projectLink: 'https://guitarswap.exchange/',
  },
  chimpy: {
    symbol: 'CHIMPY',
    address: {
      56: '0x026a3918A6D6F7B7b1b6887B8B650143b5d04732',
    },
    decimals: 18,
    projectLink: 'https://chimpyswap.com/',
  },
  mame: {
    symbol: 'MAME',
    address: {
      56: '0x2334466a66F090e2c29278C202E7124FA60a5236',
    },
    decimals: 9,
    projectLink: 'https://www.mameinubsc.com/',
  },
  jade: {
    symbol: 'JADE',
    address: {
      56: '0x7ad7242a99f21aa543f9650a56d141c57e4f6081',
    },
    decimals: 9,
    projectLink: 'https://jadeprotocol.io/',
  },
  daima: {
    symbol: 'Daima',
    address: {
      56: '0xba85367ec9339d03d77f389f5be2a2a21e4dcfea',
    },
    decimals: 18,
    projectLink: 'https://daimatoken.net',
  },
  flb: {
    symbol: 'FLB',
    address: {
      56: '0xfa15d6da38dddd0e96b9d273cb807e041e7d7f44',
    },
    decimals: 18,
    projectLink: 'https://www.flokibook.com/',
  },
  merd: {
    symbol: 'MERD',
    address: {
      56: '0xa8d3f6d1dea173a262f7ddb74a15c15858cb0113',
    },
    decimals: 18,
    projectLink: 'https://binance.mermaidswap.com/nests',
  },
  fnx: {
    symbol: 'FNX',
    address: {
      56: '0x3f6d17676a2896d80f4a33c412ec04025679a48f',
    },
    decimals: 9,
    projectLink: 'www.feenixtoken.io',
  },
  wings: {
    symbol: 'WINGS',
    address: {
      56: '0x0487b824c8261462F88940f97053E65bDb498446',
    },
    decimals: 18,
    projectLink: 'https://jetswap.finance',
  },
  yukla: {
    symbol: 'YUKLA',
    address: {
      56: '0x878f46773e99f7dd308ebf0a3a331e7ebbb24b25',
    },
    decimals: 18,
    projectLink: 'https://yuklaswap.com/',
  },
  zono: {
    symbol: 'ZONO',
    address: {
      56: '0xAc97796B45F9627e16da9C93e608579ceEb410a4',
    },
    decimals: 18,
    projectLink: 'https://farms.zonoswap.com/pools',
  },
  smile: {
    symbol: 'SMILE',
    address: {
      56: '0x74Dda58c57257461Cc96e46A5c1094141A507aEf',
    },
    decimals: 18,
    projectLink: 'https://www.smileswap.finance/farms',
  },
  key: {
    symbol: 'KEY',
    address: {
      56: '0x07b1681C082039551952bDee4A505cecC2FF4998',
    },
    decimals: 18,
    projectLink: 'https://keyswap.exchange',
  },
  afterburner: {
    symbol: 'Afterburner',
    address: {
      56: '0x6564E5815D6A1772C706D388A551F3afB6A1FE12',
    },
    decimals: 18,
    projectLink: 'https://www.stratospheredefi.com/',
  },
  bris: {
    symbol: 'BRIS',
    address: {
      56: '0x88C945170267BA9b7959E3f07706846890F79924',
    },
    decimals: 18,
    projectLink: 'https://bridgeswap.app/',
  },


  nuls: {
    symbol: 'NULS',
    address: {
      56: '0x8cd6e29d3686d24d3c2018cee54621ea0f89313b',
    },
    decimals: 8,
    projectLink: 'https://nuls.io/',
  },
  belt: {
    symbol: 'BELT',
    address: {
      56: '0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
    },
    decimals: 18,
    projectLink: 'https://beta.belt.fi/',
  },
  ramp: {
    symbol: 'RAMP',
    address: {
      56: '0x8519ea49c997f50ceffa444d240fb655e89248aa',
    },
    decimals: 18,
    projectLink: 'https://rampdefi.com/',
  },
  bfi: {
    symbol: 'BFI',
    address: {
      56: '0x81859801b01764d4f0fa5e64729f5a6c3b91435b',
    },
    decimals: 18,
    projectLink: 'https://bearn.fi/',
  },
  dexe: {
    symbol: 'DEXE',
    address: {
      56: '0x039cb485212f996a9dbb85a9a75d898f94d38da6',
    },
    decimals: 18,
    projectLink: 'https://dexe.network/',
  },
  bel: {
    symbol: 'BEL',
    address: {
      56: '0x8443f091997f06a61670b735ed92734f5628692f',
    },
    decimals: 18,
    projectLink: 'https://bella.fi/',
  },
  tpt: {
    symbol: 'TPT',
    address: {
      56: '0xeca41281c24451168a37211f0bc2b8645af45092',
    },
    decimals: 4,
    projectLink: 'https://www.tokenpocket.pro/',
  },
  watch: {
    symbol: 'WATCH',
    address: {
      56: '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0',
    },
    decimals: 18,
    projectLink: 'https://yieldwatch.net/',
  },
  xmark: {
    symbol: 'xMARK',
    address: {
      56: '0x26a5dfab467d4f58fb266648cae769503cec9580',
    },
    decimals: 9,
    projectLink: 'https://benchmarkprotocol.finance/',
  },
  bmxx: {
    symbol: 'bMXX',
    address: {
      56: '0x4131b87f74415190425ccd873048c708f8005823',
    },
    decimals: 18,
    projectLink: 'https://multiplier.finance/',
  },
  iotx: {
    symbol: 'IOTX',
    address: {
      56: '0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
    },
    decimals: 18,
    projectLink: 'https://iotex.io/',
  },
  bor: {
    symbol: 'BOR',
    address: {
      56: '0x92d7756c60dcfd4c689290e8a9f4d263b3b32241',
    },
    decimals: 18,
    projectLink: 'https://www.boringdao.com/',
  },
  bopen: {
    symbol: 'bOPEN',
    address: {
      56: '0xf35262a9d427f96d2437379ef090db986eae5d42',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  dodo: {
    symbol: 'DODO',
    address: {
      56: '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
    },
    decimals: 18,
    projectLink: 'https://dodoex.io/',
  },
  swingby: {
    symbol: 'SWINGBY',
    address: {
      56: '0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
    },
    decimals: 18,
    projectLink: 'https://swingby.network/',
  },
  bry: {
    symbol: 'BRY',
    address: {
      56: '0xf859bf77cbe8699013d6dbc7c2b926aaf307f830',
    },
    decimals: 18,
    projectLink: 'https://berrydata.co/',
  },
  zee: {
    symbol: 'ZEE',
    address: {
      56: '0x44754455564474a89358b2c2265883df993b12f0',
    },
    decimals: 18,
    projectLink: 'https://zeroswap.io/',
  },
  swgb: {
    symbol: 'SWGb',
    address: {
      56: '0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
    },
    decimals: 18,
    projectLink: 'https://swirgepay.com/',
  },
  sfp: {
    symbol: 'SFP',
    address: {
      56: '0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
    },
    decimals: 18,
    projectLink: 'https://www.safepal.io/',
  },
  lina: {
    symbol: 'LINA',
    address: {
      56: '0x762539b45a1dcce3d36d080f74d1aed37844b878',
    },
    decimals: 18,
    projectLink: 'https://linear.finance/',
  },
  lit: {
    symbol: 'LIT',
    address: {
      56: '0xb59490ab09a0f526cc7305822ac65f2ab12f9723',
    },
    decimals: 18,
    projectLink: 'https://www.litentry.com/',
  },
  hget: {
    symbol: 'HGET',
    address: {
      56: '0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
    },
    decimals: 6,
    projectLink: 'https://www.hedget.com/',
  },
  bdo: {
    symbol: 'BDO',
    address: {
      56: '0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
    },
    decimals: 18,
    projectLink: 'https://bdollar.fi/',
  },
  egld: {
    symbol: 'EGLD',
    address: {
      56: '0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
    },
    decimals: 18,
    projectLink: 'https://elrond.com/',
  },
  ust: {
    symbol: 'UST',
    address: {
      56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  wsote: {
    symbol: 'wSOTE',
    address: {
      56: '0x541e619858737031a1244a5d0cd47e5ef480342c',
    },
    decimals: 18,
    projectLink: 'https://soteria.finance/#/',
  },
  front: {
    symbol: 'FRONT',
    address: {
      56: '0x928e55dab735aa8260af3cedada18b5f70c72f1b',
    },
    decimals: 18,
    projectLink: 'https://frontier.xyz/',
  },
  helmet: {
    symbol: 'Helmet',
    address: {
      56: '0x948d2a81086a075b3130bac19e4c6dee1d2e3fe8',
    },
    decimals: 18,
    projectLink: 'https://www.helmet.insure/',
  },
  btcst: {
    symbol: 'BTCST',
    address: {
      56: '0x78650b139471520656b9e7aa7a5e9276814a38e9',
    },
    decimals: 17,
    projectLink: 'https://www.1-b.tc/',
  },
  bscx: {
    symbol: 'BSCX',
    address: {
      56: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
    },
    decimals: 18,
    projectLink: 'https://bscex.org/',
  },
  ten: {
    symbol: 'TEN',
    address: {
      56: '0xdff8cb622790b7f92686c722b02cab55592f152c',
    },
    decimals: 18,
    projectLink: 'https://www.tenet.farm/',
  },
  balbt: {
    symbol: 'bALBT',
    address: {
      56: '0x72faa679e1008ad8382959ff48e392042a8b06f7',
    },
    decimals: 18,
    projectLink: 'https://allianceblock.io/',
  },
  asr: {
    symbol: 'ASR',
    address: {
      56: '0x80d5f92c2c8c682070c95495313ddb680b267320',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  atm: {
    symbol: 'ATM',
    address: {
      56: '0x25e9d05365c867e59c1904e7463af9f312296f9e',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  og: {
    symbol: 'OG',
    address: {
      56: '0xf05e45ad22150677a017fbd94b84fbb63dc9b44c',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  reef: {
    symbol: 'REEF',
    address: {
      56: '0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
    },
    decimals: 18,
    projectLink: 'https://reef.finance/',
  },
  ditto: {
    symbol: 'DITTO',
    address: {
      56: '0x233d91a0713155003fc4dce0afa871b508b3b715',
    },
    decimals: 9,
    projectLink: 'https://ditto.money/',
  },
  juv: {
    symbol: 'JUV',
    address: {
      56: '0xc40c9a843e1c6d01b7578284a9028854f6683b1b',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  psg: {
    symbol: 'PSG',
    address: {
      56: '0xbc5609612b7c44bef426de600b5fd1379db2ecf1',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  vai: {
    symbol: 'VAI',
    address: {
      56: '0x4bd17003473389a42daf6a0a729f6fdb328bbbd7',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  wbnb: {
    symbol: 'wBNB',
    address: {
      56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  blink: {
    symbol: 'BLINK',
    address: {
      56: '0x63870a18b6e42b01ef1ad8a2302ef50b7132054f',
    },
    decimals: 6,
    projectLink: 'https://blink.wink.org',
  },
  unfi: {
    symbol: 'UNFI',
    address: {
      56: '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b',
    },
    decimals: 18,
    projectLink: 'https://unifiprotocol.com',
  },
  twt: {
    symbol: 'TWT',
    address: {
      56: '0x4b0f1812e5df2a09796481ff14017e6005508003',
    },
    decimals: 18,
    projectLink: 'https://trustwallet.com/',
  },
  hard: {
    symbol: 'HARD',
    address: {
      56: '0xf79037f6f6be66832de4e7516be52826bc3cbcc4',
    },
    decimals: 6,
    projectLink: 'https://hard.kava.io',
  },
  broobee: {
    symbol: 'bROOBEE',
    address: {
      56: '0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe',
    },
    decimals: 18,
    projectLink: 'https://roobee.io/',
  },
  stax: {
    symbol: 'STAX',
    address: {
      56: '0x0da6ed8b13214ff28e9ca979dd37439e8a88f6c4',
    },
    decimals: 18,
    projectLink: 'http://stablexswap.com/',
  },
  nar: {
    symbol: 'NAR',
    address: {
      56: '0xa1303e6199b319a891b79685f0537d289af1fc83',
    },
    decimals: 18,
    projectLink: 'https://narwhalswap.org/',
  },
  nya: {
    symbol: 'NYA',
    address: {
      56: '0xbfa0841f7a90c4ce6643f651756ee340991f99d5',
    },
    decimals: 18,
    projectLink: 'https://nyanswop.org/',
  },
  ctk: {
    symbol: 'CTK',
    address: {
      56: '0xa8c2b8eec3d368c0253ad3dae65a5f2bbb89c929',
    },
    decimals: 6,
    projectLink: 'https://www.certik.foundation/',
  },
  inj: {
    symbol: 'INJ',
    address: {
      56: '0xa2b726b1145a4773f68593cf171187d8ebe4d495',
    },
    decimals: 18,
    projectLink: 'https://injectiveprotocol.com/',
  },
  sxp: {
    symbol: 'SXP',
    address: {
      56: '0x47bead2563dcbf3bf2c9407fea4dc236faba485a',
    },
    decimals: 18,
    projectLink: 'https://swipe.io/',
  },
  alpha: {
    symbol: 'ALPHA',
    address: {
      56: '0xa1faa113cbe53436df28ff0aee54275c13b40975',
    },
    decimals: 18,
    projectLink: 'https://alphafinance.io/',
  },
  xvs: {
    symbol: 'XVS',
    address: {
      56: '0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
    },
    decimals: 18,
    projectLink: 'https://sushi.com/',
  },
  comp: {
    symbol: 'COMP',
    address: {
      56: '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
    },
    decimals: 18,
    projectLink: 'https://compound.finance/',
  },
  syrup: {
    symbol: 'SYRUP',
    address: {
      56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
      97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  bifi: {
    symbol: 'BIFI',
    address: {
      56: '0xca3f508b8e4dd382ee878a314789373d80a5190a',
    },
    decimals: 18,
    projectLink: 'https://beefy.finance/',
  },
  dusk: {
    symbol: 'DUSK',
    address: {
      56: '0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
    },
    decimals: 18,
    projectLink: 'https://dusk.network/',
  },
  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },
  beth: {
    symbol: 'BETH',
    address: {
      56: '0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
  },
  mamzn: {
    symbol: 'mAMZN',
    address: {
      56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mgoogl: {
    symbol: 'mGOOGL',
    address: {
      56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mnflx: {
    symbol: 'mNFLX',
    address: {
      56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mtsla: {
    symbol: 'mTSLA',
    address: {
      56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  ltc: {
    symbol: 'LTC',
    address: {
      56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    },
    decimals: 18,
    projectLink: 'https://litecoin.org/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
    },
    decimals: 18,
    projectLink: 'http://www.makerdao.com/',
  },
  ada: {
    symbol: 'ADA',
    address: {
      56: '0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.cardano.org/',
  },
  band: {
    symbol: 'BAND',
    address: {
      56: '0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bandprotocol.com/',
  },
  dot: {
    symbol: 'DOT',
    address: {
      56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://polkadot.network/',
  },
  eos: {
    symbol: 'EOS',
    address: {
      56: '0x56b6fb708fc5732dec1afc8d8556423a2edccbd6',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://eos.io/',
  },
  link: {
    symbol: 'LINK',
    address: {
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x55d398326f99059fF775485246999027B3197955',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  xrp: {
    symbol: 'XRP',
    address: {
      56: '0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://ripple.com/xrp/',
  },
  atom: {
    symbol: 'ATOM',
    address: {
      56: '0x0eb3a705fc54725037cc9e008bdede697f62f335',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://cosmos.network/',
  },
  yfii: {
    symbol: 'YFII',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x7f70642d88cf1c4a3a7abb072b53b929b653eda5',
    },
    decimals: 18,
    projectLink: 'https://dfi.money/#/',
  },
  xtz: {
    symbol: 'XTZ',
    address: {
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      56: '0x16939ef78684453bfdfb47825f8a5f714f12623a',
    },
    decimals: 18,
    projectLink: 'https://www.tezos.com/',
  },
  bch: {
    symbol: 'BCH',
    address: {
      56: '0x8ff795a6f4d97e7887c79bea79aba5cc76444adf',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'http://bch.info/',
  },
  yfi: {
    symbol: 'YFI',
    address: {
      56: '0x88f1a5ae2a3bf98aeaf342d26b30a79438c9142e',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://yearn.finance/',
  },
  uni: {
    symbol: 'UNI',
    address: {
      56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://uniswap.org/',
  },
  fil: {
    symbol: 'FIL',
    address: {
      56: '0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://filecoin.io/',
  },
  bake: {
    symbol: 'BAKE',
    address: {
      56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.bakeryswap.org/',
  },
  burger: {
    symbol: 'BURGER',
    address: {
      56: '0xae9269f27437f0fcbc232d39ec814844a51d6b8f',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://burgerswap.org/',
  },
  bdigg: {
    symbol: 'bDIGG',
    address: {
      56: '0x5986d5c77c65e5801a5caa4fae80089f870a71da',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  bbadger: {
    symbol: 'bBadger',
    address: {
      56: '0x1f7216fdb338247512ec99715587bb97bbf96eae',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  trade: {
    symbol: 'TRADE',
    address: {
      56: '0x7af173f350d916358af3e218bdf2178494beb748',
    },
    decimals: 18,
    projectLink: 'https://unitrade.app/',
  },
  pnt: {
    symbol: 'PNT',
    address: {
      56: '0x7a1da9f49224ef98389b071b8a3294d1cc5e3e6a',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  mir: {
    symbol: 'MIR',
    address: {
      56: '0x5b6dcf557e2abe2323c48445e8cc948910d8c2c9',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  pbtc: {
    symbol: 'pBTC',
    address: {
      56: '0xed28a457a5a76596ac48d87c0f577020f6ea1c4c',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  lto: {
    symbol: 'LTO',
    address: {
      56: '0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
    },
    decimals: 18,
    projectLink: 'https://ltonetwork.com/',
  },
  pcws: {
    symbol: 'pCWS',
    address: {
      56: '0xbcf39f0edda668c58371e519af37ca705f2bfcbd',
    },
    decimals: 18,
    projectLink: 'https://game.seascape.network/',
  },
  zil: {
    symbol: 'ZIL',
    address: {
      56: '0xb86abcb37c3a4b64f74f59301aff131a1becc787',
    },
    decimals: 12,
    projectLink: 'https://www.zilliqa.com/',
  },
  lien: {
    symbol: 'LIEN',
    address: {
      56: '0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
    },
    decimals: 8,
    projectLink: 'https://lien.finance/',
  },
  swth: {
    symbol: 'SWTH',
    address: {
      56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
    },
    decimals: 8,
    projectLink: 'https://switcheo.network/',
  },
  dft: {
    symbol: 'DFT',
    address: {
      56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
    },
    decimals: 18,
    projectLink: 'https://www.dfuture.com/home',
  },
  gum: {
    symbol: 'GUM',
    address: {
      56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
    },
    decimals: 18,
    projectLink: 'https://gourmetgalaxy.io/',
  },
  dego: {
    symbol: 'DEGO',
    address: {
      56: '0x3fda9383a84c05ec8f7630fe10adf1fac13241cc',
    },
    decimals: 18,
    projectLink: 'https://bsc.dego.finance/home',
  },
  nrv: {
    symbol: 'NRV',
    address: {
      56: '0x42f6f551ae042cbe50c739158b4f0cac0edb9096',
    },
    decimals: 18,
    projectLink: 'https://nerve.fi/',
  },
  easy: {
    symbol: 'EASY',
    address: {
      56: '0x7c17c8bed8d14bacce824d020f994f4880d6ab3b',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  oddz: {
    symbol: 'ODDZ',
    address: {
      56: '0xcd40f2670cf58720b694968698a5514e924f742d',
    },
    decimals: 18,
    projectLink: 'https://oddz.fi/',
  },
  hoo: {
    symbol: 'HOO',
    address: {
      56: '0xe1d1f66215998786110ba0102ef558b22224c016',
    },
    decimals: 8,
    projectLink: 'https://hoo.com/',
  },
  apys: {
    symbol: 'APYS',
    address: {
      56: '0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
    },
    decimals: 18,
    projectLink: 'https://apyswap.com/',
  },
  bondly: {
    symbol: 'BONDLY',
    address: {
      56: '0x96058f8c3e16576d9bd68766f3836d9a33158f89',
    },
    decimals: 18,
    projectLink: 'https://www.bondly.finance/',
  },
  tko: {
    symbol: 'TKO',
    address: {
      56: '0x9f589e3eabe42ebc94a44727b3f3531c0c877809',
    },
    decimals: 18,
    projectLink: 'https://www.tokocrypto.com/',
  },
  itam: {
    symbol: 'ITAM',
    address: {
      56: '0x04c747b40be4d535fc83d09939fb0f626f32800b',
    },
    decimals: 18,
    projectLink: 'https://itam.network/',
  },
  arpa: {
    symbol: 'ARPA',
    address: {
      56: '0x6f769e65c14ebd1f68817f5f1dcdb61cfa2d6f7e',
    },
    decimals: 18,
    projectLink: 'https://arpachain.io/',
  },
  eps: {
    symbol: 'EPS',
    address: {
      56: '0xa7f552078dcc247c2684336020c03648500c6d9f',
    },
    decimals: 18,
    projectLink: 'https://ellipsis.finance/',
  },
  jgn: {
    symbol: 'JGN',
    address: {
      56: '0xc13b7a43223bb9bf4b69bd68ab20ca1b79d81c75',
    },
    decimals: 18,
    projectLink: 'https://jgndefi.com/',
  },
  tlm: {
    symbol: 'TLM',
    address: {
      56: '0x2222227e22102fe3322098e4cbfe18cfebd57c95',
    },
    decimals: 4,
    projectLink: 'https://alienworlds.io/',
  },
  perl: {
    symbol: 'PERL',
    address: {
      56: '0x0f9e4d49f25de22c2202af916b681fbb3790497b',
    },
    decimals: 18,
    projectLink: 'https://perlinx.finance/',
  },
  alpa: {
    symbol: 'ALPA',
    address: {
      56: '0xc5e6689c9c8b02be7c49912ef19e79cf24977f03',
    },
    decimals: 18,
    projectLink: 'https://bsc.alpaca.city/',
  },
  hzn: {
    symbol: 'HZN',
    address: {
      56: '0xc0eff7749b125444953ef89682201fb8c6a917cd',
    },
    decimals: 18,
    projectLink: 'https://horizonprotocol.com/',
  },
  suter: {
    symbol: 'SUTER',
    address: {
      56: '0x4cfbbdfbd5bf0814472ff35c72717bd095ada055',
    },
    decimals: 18,
    projectLink: 'https://shield.suterusu.io/',
  },
  cgg: {
    symbol: 'CGG',
    address: {
      56: '0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
    },
    decimals: 18,
    projectLink: 'https://chainguardians.io/',
  },
  mix: {
    symbol: 'MIX',
    address: {
      56: '0xB67754f5b4C704A24d2db68e661b2875a4dDD197',
    },
    decimals: 18,
    projectLink: 'https://mixie.chainguardians.io/',
  },
  hakka: {
    symbol: 'HAKKA',
    address: {
      56: '0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
    },
    decimals: 18,
    projectLink: 'https://hakka.finance/',
  },
  xed: {
    symbol: 'XED',
    address: {
      56: '0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
    },
    decimals: 18,
    projectLink: 'https://www.exeedme.com/',
  },
  τbtc: {
    symbol: 'τBTC',
    address: {
      56: '0x2cd1075682b0fccaadd0ca629e138e64015ba11c',
    },
    decimals: 9,
    projectLink: 'https://www.btcst.finance/',
  },
  alpaca: {
    symbol: 'ALPACA',
    address: {
      56: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
    },
    decimals: 18,
    projectLink: 'https://www.alpacafinance.org/',
  },
  dfd: {
    symbol: 'DFD',
    address: {
      56: '0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
    },
    decimals: 18,
    projectLink: 'https://dusd.finance/',
  },
  lmt: {
    symbol: 'LMT',
    address: {
      56: '0x9617857e191354dbea0b714d78bc59e57c411087',
    },
    decimals: 18,
    projectLink: 'https://lympo.io/lmt/',
  },
  kalm: {
    symbol: 'KALM',
    address: {
      56: '0x4BA0057f784858a48fe351445C672FF2a3d43515',
    },
    decimals: 18,
    projectLink: 'https://kalmar.io/',
  },
  deri: {
    symbol: 'DERI',
    address: {
      56: '0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5',
    },
    decimals: 18,
    projectLink: 'https://deri.finance/#/index',
  },
  well: {
    symbol: 'WELL',
    address: {
      56: '0xf07a32eb035b786898c00bb1c64d8c6f8e7a46d5',
    },
    decimals: 18,
    projectLink: 'https://www.bitwellex.com/',
  },
  popen: {
    symbol: 'pOPEN',
    address: {
      56: '0xabae871b7e3b67aeec6b46ae9fe1a91660aadac5',
    },
    decimals: 18,
    projectLink: 'https://opendao.io/',
  },
  btt: {
    symbol: 'BTT',
    address: {
      56: '0x8595f9da7b868b1822194faed312235e43007b49',
    },
    decimals: 18,
    projectLink: 'https://www.bittorrent.com/',
  },
  trx: {
    symbol: 'TRX',
    address: {
      56: '0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
    },
    decimals: 18,
    projectLink: 'https://tron.network/',
  },
  win: {
    symbol: 'WIN',
    address: {
      56: '0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
    },
    decimals: 18,
    projectLink: 'https://winklink.org/',
  },
  mcoin: {
    symbol: 'mCOIN',
    address: {
      56: '0x49022089e78a8d46ec87a3af86a1db6c189afa6f',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  math: {
    symbol: 'MATH',
    address: {
      56: '0xf218184af829cf2b0019f8e6f0b2423498a36983',
    },
    decimals: 18,
    projectLink: 'https://mathwallet.org/',
  },
  kun: {
    symbol: 'KUN',
    address: {
      56: '0x1A2fb0Af670D0234c2857FaD35b789F8Cb725584',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  qsd: {
    symbol: 'QSD',
    address: {
      56: '0x07AaA29E63FFEB2EBf59B33eE61437E1a91A3bb2',
    },
    decimals: 18,
    projectLink: 'https://chemix.io/home',
  },
  hyfi: {
    symbol: 'HYFI',
    address: {
      56: '0x9a319b959e33369C5eaA494a770117eE3e585318',
    },
    decimals: 18,
    projectLink: 'https://hyfi.pro/#/',
  },
  oin: {
    symbol: 'OIN',
    address: {
      56: '0x658E64FFcF40D240A43D52CA9342140316Ae44fA',
    },
    decimals: 8,
    projectLink: 'https://oin.finance/',
  },
  fine: {
    symbol: 'FINE',
    address: {
      56: '0x4e6415a5727ea08aae4580057187923aec331227',
    },
    decimals: 18,
    projectLink: 'https://refinable.com/',
  },
  one: {
    symbol: 'ONE',
    address: {
      56: '0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0',
    },
    decimals: 18,
    projectLink: 'https://www.bigone.com/',
  },
  pmon: {
    symbol: 'PMON',
    address: {
      56: '0x1796ae0b0fa4862485106a0de9b654eFE301D0b2',
    },
    decimals: 18,
    projectLink: 'https://polkamon.com/',
  },
  hotcross: {
    symbol: 'HOTCROSS',
    address: {
      56: '0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
    },
    decimals: 18,
    projectLink: 'https://www.hotcross.com/',
  },
  τdoge: {
    symbol: 'τDOGE',
    address: {
      56: '0xe550a593d09fbc8dcd557b5c88cea6946a8b404a',
    },
    decimals: 8,
    projectLink: 'https://www.btcst.finance/',
  },
  btr: {
    symbol: 'BTR',
    address: {
      56: '0x5a16E8cE8cA316407c6E6307095dc9540a8D62B3',
    },
    decimals: 18,
    projectLink: 'https://www.bitrue.com/',
  },
  ubxt: {
    symbol: 'UBXT',
    address: {
      56: '0xbbeb90cfb6fafa1f69aa130b7341089abeef5811',
    },
    decimals: 18,
    projectLink: 'https://upbots.com/',
  },
  wmass: {
    symbol: 'WMASS',
    address: {
      56: '0x7e396bfc8a2f84748701167c2d622f041a1d7a17',
    },
    decimals: 8,
    projectLink: 'https://massnet.org/en/',
  },
  rfox: {
    symbol: 'RFOX',
    address: {
      56: '0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
    },
    decimals: 18,
    projectLink: 'https://www.redfoxlabs.io/',
  },
  xend: {
    symbol: 'XEND',
    address: {
      56: '0x4a080377f83D669D7bB83B3184a8A5E61B500608',
    },
    decimals: 18,
    projectLink: 'https://xend.finance/',
  },
  cyc: {
    symbol: 'CYC',
    address: {
      56: '0x810ee35443639348adbbc467b33310d2ab43c168',
    },
    decimals: 18,
    projectLink: 'https://cyclone.xyz/',
  },
  chr: {
    symbol: 'CHR',
    address: {
      56: '0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
    },
    decimals: 6,
    projectLink: 'https://chromia.com/',
  }
}

export default tokens
