import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { minBy, orderBy } from 'lodash'
import { BigNumber } from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { getWeb3NoAccount } from 'utils/web3'
import { State, NodeRound, ReduxNodeLedger, NodeLedger, ReduxNodeRound, Farm, FarmsState } from './types'
import { parseBigNumberObj } from './predictions/helpers'
import { useFarms } from './farms/hooks'
import { BIG_ZERO } from '../utils/bigNumber'
import { getBalanceNumber } from '../utils/formatBalance'
import { usePools } from './pools/hooks'
import tokens from '../config/constants/tokens'
import { useAppDispatch } from './index'
import useRefresh from '../hooks/useRefresh'
import { fetchPoolsPublicDataAsync, fetchPoolsStakingLimitsAsync } from './pools'

// /!\
// Don't add anything here. These hooks will be moved the the predictions folder

// Predictions
export const useGetRounds = () => {
  const rounds = useSelector((state: State) => state.predictions.rounds)
  return Object.keys(rounds).reduce((accum, epoch) => {
    return {
      ...accum,
      [epoch]: parseBigNumberObj<ReduxNodeRound, NodeRound>(rounds[epoch]),
    }
  }, {}) as { [key: string]: NodeRound }
}

export const useGetRound = (epoch: number) => {
  const round = useSelector((state: State) => state.predictions.rounds[epoch])
  return parseBigNumberObj<ReduxNodeRound, NodeRound>(round)
}

export const useGetSortedRounds = () => {
  const roundData = useGetRounds()
  return orderBy(Object.values(roundData), ['epoch'], ['asc'])
}

export const useGetBetByEpoch = (account: string, epoch: number) => {
  const bets = useSelector((state: State) => state.predictions.ledgers)

  if (!bets[account]) {
    return null
  }

  if (!bets[account][epoch]) {
    return null
  }

  return parseBigNumberObj<ReduxNodeLedger, NodeLedger>(bets[account][epoch])
}

export const useGetIsClaimable = (epoch) => {
  const claimableStatuses = useSelector((state: State) => state.predictions.claimableStatuses)
  return claimableStatuses[epoch] || false
}

/**
 * Used to get the range of rounds to poll for
 */
export const useGetEarliestEpoch = () => {
  return useSelector((state: State) => {
    const earliestRound = minBy(Object.values(state.predictions.rounds), 'epoch')
    return earliestRound?.epoch
  })
}

export const useIsHistoryPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isHistoryPaneOpen)
}

export const useIsChartPaneOpen = () => {
  return useSelector((state: State) => state.predictions.isChartPaneOpen)
}

export const useGetCurrentEpoch = () => {
  return useSelector((state: State) => state.predictions.currentEpoch)
}

export const useGetIntervalBlocks = () => {
  return useSelector((state: State) => state.predictions.intervalBlocks)
}

export const useGetBufferBlocks = () => {
  return useSelector((state: State) => state.predictions.bufferBlocks)
}

export const useGetTotalIntervalBlocks = () => {
  const intervalBlocks = useGetIntervalBlocks()
  const bufferBlocks = useGetBufferBlocks()
  return intervalBlocks + bufferBlocks
}

export const useGetCurrentRound = () => {
  const currentEpoch = useGetCurrentEpoch()
  const rounds = useGetSortedRounds()
  return rounds.find((round) => round.epoch === currentEpoch)
}

export const useGetPredictionsStatus = () => {
  return useSelector((state: State) => state.predictions.status)
}

export const useGetHistoryFilter = () => {
  return useSelector((state: State) => state.predictions.historyFilter)
}

export const useGetCurrentRoundBlockNumber = () => {
  return useSelector((state: State) => state.predictions.currentRoundStartBlockNumber)
}

export const useGetMinBetAmount = () => {
  const minBetAmount = useSelector((state: State) => state.predictions.minBetAmount)
  return useMemo(() => ethers.BigNumber.from(minBetAmount), [minBetAmount])
}

export const useGetRewardRate = () => {
  const rewardRate = useSelector((state: State) => state.predictions.rewardRate)
  return rewardRate / 100
}

export const useGetIsFetchingHistory = () => {
  return useSelector((state: State) => state.predictions.isFetchingHistory)
}

export const useGetHistory = () => {
  return useSelector((state: State) => state.predictions.history)
}

export const useGetHistoryByAccount = (account: string) => {
  const bets = useGetHistory()
  return bets ? bets[account] : []
}

export const useGetLedgerByRoundId = (account: string, roundId: string) => {
  const ledgers = useSelector((state: State) => state.predictions.ledgers)

  if (!ledgers[account]) {
    return null
  }

  if (!ledgers[account][roundId]) {
    return null
  }

  return ledgers[account][roundId]
}

export const useGetLastOraclePrice = () => {
  const lastOraclePrice = useSelector((state: State) => state.predictions.lastOraclePrice)
  return useMemo(() => {
    return ethers.BigNumber.from(lastOraclePrice)
  }, [lastOraclePrice])
}

// Prices

export const usePriceBnbBusd = (): BigNumber => {
  const bnbBusdFarm = useFarmFromPid(4)  // BNB-BUSD
  return new BigNumber(bnbBusdFarm.token.busdPrice)
}

export const usePriceOliveBusd = (): BigNumber => {
  const farm = useFarmFromPid(10) // OLIVE-BNB
  return new BigNumber(farm.token.busdPrice)
}
export const useFarmFromPid = (pid): Farm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return farm
}

const getTokenPrice = (tokenSymbol: string, farms: FarmsState, bnbPrice: BigNumber) => {
  const tokenFarm = farms.data.find((f) => f.token.symbol === tokenSymbol)
  const priceInBNB = new BigNumber(tokenFarm?.tokenPriceVsQuote)
  return priceInBNB.times(bnbPrice)
}

export const useTotalValue = (): BigNumber => {
  const farms = useFarms()
  const bnbPrice = usePriceBnbBusd()
  let olivePrice = usePriceOliveBusd()
  if (olivePrice.isNaN()) olivePrice = BIG_ZERO

  let value = new BigNumber(0)
  farms.data.forEach(farm => {
    if (farm.lpTotalInQuoteToken) {
      const farmQuotePrice = new BigNumber(farm.quoteToken.busdPrice)
      const farmTvl = new BigNumber(farm.lpTotalInQuoteToken).times(farmQuotePrice)
      value = value.plus(farmTvl)
    }
  })

  const { account } = useWeb3React()
  const pools = usePools(account)
  pools.pools.filter(pool => pool.totalStaked.isPositive()).forEach(pool => {
    const token = pool.stakingToken
    const price = token.symbol === tokens.olive.symbol ? olivePrice : getTokenPrice(token.symbol, farms, bnbPrice)
    const staked = new BigNumber(getBalanceNumber(pool.totalStaked, token.decimals)).times(price)
    value = value.plus(staked)
  })

  return value
}

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const web3 = getWeb3NoAccount()

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await web3.eth.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(blockNumber))
    }

    fetchPoolsPublicData()
    dispatch(fetchPoolsStakingLimitsAsync())
  }, [dispatch, slowRefresh, web3])
}
