import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    stakingToken: tokens.olive,
    earningToken: tokens.olive,
    contractAddress: {
      56: '0xD92bc4Afc7775FF052Cdac90352c39Cb6a455900',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.1669634907',
    sortOrder: 1,
    isFinished: false,
  },
  // === Eternal pools ===
  {
    sousId: 206,
    stakingToken: tokens.olive,
    earningToken: tokens.yukla,
    contractAddress: {
      56: '0xBBC0C95a839deA1d5482701AB7Fa84f27B5c8fd9',
    },
    poolId: 30,
    pending: 'pendingYukla',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.04511278195',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 205,
    stakingToken: tokens.olive,
    earningToken: tokens.frt,
    contractAddress: {
      56: '0xEc82cd083f7A0F0F533b82aa3C2f40079914B9a4',
    },
    poolId: 68,
    pending: 'pendingCake',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.0128564',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 204,
    stakingToken: tokens.olive,
    earningToken: tokens.merd,
    contractAddress: {
      56: '0xa15625689a4a0556f9de3e37beda9df4e2d8df82',
    },
    poolId: 17,
    pending: 'pendingMerd',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.0004761904762',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 203,
    stakingToken: tokens.olive,
    earningToken: tokens.coris,
    contractAddress: {
      56: '0x60E5Cf9111d046E8F986fC98e37d6703607d5Baf',
    },
    poolId: 43,
    pending: 'pendingCorgiS',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.005444646098',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 202,
    stakingToken: tokens.olive,
    earningToken: tokens.cgs,
    contractAddress: {
      56: '0x8E934F14bD904A46e0C8aF7de6aEeAaaa0D8C2c5',
    },
    poolId: 34,
    pending: 'pendingCougar',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.060606060618',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 201,
    stakingToken: tokens.olive,
    earningToken: tokens.redbuff,
    contractAddress: {
      56: '0xE4B6C160F4875040A8383Ac3623E150397632FE2',
    },
    poolId: 10,
    pending: 'pendingRedBuff',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.001086956522',
    sortOrder: 1,
    isFinished: false,
  },
  // === Finite pools ===
  // {
  //   sousId: 126, // BIG TIMER
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.sitx,
  //   contractAddress: {
  //     56: '0x852edF924321d234B433066Aa89E3d00B4083fB5',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // {
  //   sousId: 135, // BIG TIMER
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.jizzd,
  //   contractAddress: {
  //     56: '0xf0c0e607c36ccbf370ed4989359f187721ff8c8f',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1', // CHECK
  //   isFinished: false,
  // },
  // {
  //   sousId: 138,
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.jade,
  //   contractAddress: {
  //     56: '0x87038779dc27cde751a584d5fa0158703692d4dd',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '0.000008',
  //   isFinished: false,
  // },
  // {
  //   sousId: 154,
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.sensi,
  //   contractAddress: {
  //     56: '0x080a41ded9679b893bfcebf83797e910b42d562c',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '440000', // CHECK + maybe never launched
  //   isFinished: false,
  // },
  {
    sousId: 160,
    stakingToken: tokens.olive,
    earningToken: tokens.afterburner,
    contractAddress: {
      56: '0x3eb4595ab62c5a884f1687563cc74ea793eefe2c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '4.8',
    isFinished: false,
  },
  {
    sousId: 159,
    stakingToken: tokens.olive,
    earningToken: tokens.daima,
    contractAddress: {
      56: '0x77dde076159b4fcbf674064bc6c00ea624f94c1f',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '5495',
    isFinished: false,
  },
  {
    sousId: 158,
    stakingToken: tokens.olive,
    earningToken: tokens.key,
    contractAddress: {
      56: '0x093BCE5012420f8Ce1cB390d9Ad3aDd85D71A4B0',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.173',
    isFinished: true,
  },
  {
    sousId: 157,
    stakingToken: tokens.olive,
    earningToken: tokens.smile,
    contractAddress: {
      56: '0xE91442fCb0D48ba40c32f31c93F37ea6776c6ad8',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3.1',
    isFinished: true,
  },
  {
    sousId: 156,
    stakingToken: tokens.olive,
    earningToken: tokens.zono,
    contractAddress: {
      56: '0x4946AEdb22288ef5E9D69E37379448928EC0e630',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0588',
    isFinished: true,
  },
  {
    sousId: 155,
    stakingToken: tokens.olive,
    earningToken: tokens.flokiMigrated,
    contractAddress: {
      56: '0xD2a1B124272E56Def27F65DB5cf8efF87ecB3a6D',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '988004', 
    isFinished: true,
  },
  {
    sousId: 154,
    stakingToken: tokens.olive,
    earningToken: tokens.daima,
    contractAddress: {
      56: '0x0d8aAeCde54Ab1847a13Fe5e8cF5581C5A8c38AE',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '8603',
    isFinished: true,
  },
  {
    sousId: 153,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x6dddadce17447e8cda9e2046b44d164028b6c396',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '15300',
    isFinished: true,
  },
  {
    sousId: 152,
    stakingToken: tokens.olive,
    earningToken: tokens.seachain,
    contractAddress: {
      56: '0x229760ccc053f022bc921fbb07635fe9bcc8c0f1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2800',
    isFinished: true,
  },
  {
    sousId: 151,
    stakingToken: tokens.olive,
    earningToken: tokens.wings,
    contractAddress: {
      56: '0x8A40Ec30f8E6F77d410a2feE850d94dC0A0B3F7c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.284',
    isFinished: true,
  },
  {
    sousId: 150,
    stakingToken: tokens.olive,
    earningToken: tokens.if1,
    contractAddress: {
      56: '0xeeb4a7faeb582852a33abfb76854873257011381',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '100101',
    isFinished: true,
  },
  {
    sousId: 149,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x9763a97b1298fe72a1c74b8a0ef8edf937b2ec1c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '440000',
    isFinished: true,
  },
  {
    sousId: 148,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0x494af3766b7e26423779c4e2b89b821247e528fd',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '240',
    isFinished: true,
  },
  {
    sousId: 147,
    stakingToken: tokens.olive,
    earningToken: tokens.fnx,
    contractAddress: {
      56: '0xcc0a9ad632a3e1db25c624eb0fb224674117d4ca',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '53',
    isFinished: true,
  },
  {
    sousId: 146,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x2b5ecacc25b7cf7bb87d8aa70ed7c66c0347f328',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '20002',
    isFinished: true,
  },
  {
    sousId: 145,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x46116c6a56fdf25fb628dd3b0feff177e4f6a0a7',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '320000',
    isFinished: true,
  },
  {
    sousId: 144,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0xbd5bef5b7859eb95428a2d1e03c8aca50b72f058',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '141.5',
    isFinished: true,
  },
  {
    sousId: 143,
    stakingToken: tokens.olive,
    earningToken: tokens.if1,
    contractAddress: {
      56: '0xee5e866173819309e6a28060eb88c01b7faf2e3b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '100000', // CHECK
    isFinished: true,
  },
  {
    sousId: 142,
    stakingToken: tokens.olive,
    earningToken: tokens.seachain,
    contractAddress: {
      56: '0xde6f3441a383d7ff3a6a9919916bb32eb67232c1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1520',
    isFinished: true,
  },
  {
    sousId: 141,
    stakingToken: tokens.olive,
    earningToken: tokens.flb,
    contractAddress: {
      56: '0x01b612db7af794c113e5890734eaf0a67caae414',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2380',
    isFinished: true,
  },

  {
    sousId: 140,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x561cedd83f031876d9c9366ed0b20a80296a33a5',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '195000',
    isFinished: true,
  },
  {
    sousId: 139,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0xe084eea0df56f067f1bcfc7b99355339a8bd4c76',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '117.55',
    isFinished: true,
  },
  {
    sousId: 138,
    stakingToken: tokens.olive,
    earningToken: tokens.eggc,
    contractAddress: {
      56: '0xd5cC523387c7Bc473c40B147c760d333F080b99e',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3',
    isFinished: true,
  },
  {
    sousId: 137,
    stakingToken: tokens.olive,
    earningToken: tokens.chimpy,
    contractAddress: {
      56: '0x7bA3b2313B60E2b88787148Da98127a8FAc9c71e',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '10',
    isFinished: true,
  },
  {
    sousId: 136,
    stakingToken: tokens.olive,
    earningToken: tokens.gut,
    contractAddress: {
      56: '0xE389c13C96677BB0Ede8E5fc31c8471Ac6A9c6De',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.122',
    isFinished: true,
  },
  {
    sousId: 135,
    stakingToken: tokens.olive,
    earningToken: tokens.mame,
    contractAddress: {
      56: '0xb9Fdc70A401906Ce0FDA77a06154DF5B4D8D5Dd9',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '129870',
    isFinished: true,
  },
  {
    sousId: 134,
    stakingToken: tokens.olive,
    earningToken: tokens.cmon,
    contractAddress: {
      56: '0x08057ba04f33c90bd7d52b63914c7daa5d085c2b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '80',
    isFinished: true,
  },
  {
    sousId: 133,
    stakingToken: tokens.olive,
    earningToken: tokens.rshib,
    contractAddress: {
      56: '0x931c85bc7354fd9d523eefd11d455809b97773ff',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '56401440305',
    isFinished: true,
  },
  {
    sousId: 132,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x12a0ef161f7eaac9d50d5d7e2eb61cc5609b5bb2',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '170000',
    isFinished: true,
  },
  {
    sousId: 131,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0x979a68f1308d2c2e08d56a6c9a770f48aa22f07b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '160',
    isFinished: true,
  },
  {
    sousId: 130,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x64fa429cbac73fd59d0f1e1637f94dd45d4cbea7',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '5360',
    isFinished: true,
  },
  {
    sousId: 129,
    stakingToken: tokens.olive,
    earningToken: tokens.if1,
    contractAddress: {
      56: '0xECf483f18422c1a812d9AfCeeC3a4f491BFE0565',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '40000',
    isFinished: true,
  },
  {
    sousId: 128,
    stakingToken: tokens.olive,
    earningToken: tokens.shio,
    contractAddress: {
      56: '0xde3c3a6fc910b31527e8c68ddb2f712ac351a179',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '321.8',
    isFinished: true,
  },
  {
    sousId: 127,
    stakingToken: tokens.olive,
    earningToken: tokens.cdoge,
    contractAddress: {
      56: '0xDD1f9485103Ce15384648ED245F59fb004F7fD26',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '45265',
    isFinished: true,
  },
  {
    sousId: 126,
    stakingToken: tokens.olive,
    earningToken: tokens.cgu,
    contractAddress: {
      56: '0x68129311d7c8ba4507374e36467270c1fded10ef',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0025',
    isFinished: true,
  },
  {
    sousId: 125,
    stakingToken: tokens.olive,
    earningToken: tokens.game1,
    contractAddress: {
      56: '0x6d5a6017c517251de7b4e472f683ae64064a2167',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.06',
    isFinished: true,
  },
  {
    sousId: 124,
    stakingToken: tokens.olive,
    earningToken: tokens.stkd,
    contractAddress: {
      56: '0xa9a47fd9772b21cda5b96dcb1016fd7c97489438',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2556',
    isFinished: true,
  },
  {
    sousId: 123,
    stakingToken: tokens.olive,
    earningToken: tokens.bbrb,
    contractAddress: {
      56: '0x71dbde86ee160fe69b929b8e955724e1700f8c17',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3000000',
    isFinished: true,
  },
  {
    sousId: 122,
    stakingToken: tokens.olive,
    earningToken: tokens.husky,
    contractAddress: {
      56: '0x6d4cb6000b03a81b7eec4044401528ab15a8cd49',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '44575',
    isFinished: true,
  },
  {
    sousId: 121,
    stakingToken: tokens.olive,
    earningToken: tokens.hero,
    contractAddress: {
      56: '0x612a2a3723307fc6f73c8b76a5cc5a1c90e63a30',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '500',
    isFinished: true,
  },
  {
    sousId: 200,
    stakingToken: tokens.olive,
    earningToken: tokens.fswap,
    contractAddress: {
      56: '0x7Df83cd8CA76D89A69f3aB30aA11295a3F502488',
    },
    poolId: 40,
    pending: 'pendingFSwap',
    referrer: '0xCDee5108413E80aF6c84BDD3AB0d328A3d63DdA4',
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    tokenPerBlock: '0.406779661',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 120,
    stakingToken: tokens.olive,
    earningToken: tokens.seachain,
    contractAddress: {
      56: '0x3750afab8fd5581a0d31314517a1c30ab9a2f6d6',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1749',
    isFinished: true,
  },
  {
    sousId: 119,
    stakingToken: tokens.olive,
    earningToken: tokens.cht,
    contractAddress: {
      56: '0x08A87F9a7dDB8cd2644Fc737839d2A955cD94F8A',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.1',
    isFinished: true,
  },
  {
    sousId: 118,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x3c07a3ad40cce4f7877651638499759df2f77699',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '250000',
    isFinished: true,
  },
  {
    sousId: 117,
    stakingToken: tokens.olive,
    earningToken: tokens.lks,
    contractAddress: {
      56: '0xE21a0A6A22eF07837065a2D3aa32FA970E84C2c1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2.33116',
    isFinished: true,
  },
  {
    sousId: 116,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x9994eb296948bf599f6934fcc213236aa89cd70a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '6660',
    isFinished: true,
  },
  {
    sousId: 115,
    stakingToken: tokens.olive,
    earningToken: tokens.fcf,
    contractAddress: {
      56: '0xf2834015aFaC9A20f6D6214FC75D42c65b6e6448',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '140',
    isFinished: true,
  },
  {
    sousId: 114,
    stakingToken: tokens.olive,
    earningToken: tokens.cff,
    contractAddress: {
      56: '0xaDD84da7768501fa50E638504018982194C4e4B0',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2',
    isFinished: true,
  },
  {
    sousId: 113,
    stakingToken: tokens.olive,
    earningToken: tokens.supr,
    contractAddress: {
      56: '0x32ae81f6b9e79b12de1df785c8934d3b5ef05759',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2781815',
    isFinished: true,
  },
  {
    sousId: 112,
    stakingToken: tokens.olive,
    earningToken: tokens.shak,
    contractAddress: {
      56: '0x4b9755067f90d5c0b6e40417ce1872b9e3c3b248',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '7.2',
    isFinished: true,
  },
  {
    sousId: 111,
    stakingToken: tokens.olive,
    earningToken: tokens.qua,
    contractAddress: {
      56: '0x481959efefcaef59d678f8b2c5c85dc135f7cdf8',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '12',
    isFinished: true,
  },
  {
    sousId: 110,
    stakingToken: tokens.olive,
    earningToken: tokens.balpac,
    contractAddress: {
      56: '0x3375395e4334a893b70b051248809f4b4d0a835e',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '56.54',
    isFinished: true,
  },
  {
    sousId: 109,
    stakingToken: tokens.olive,
    earningToken: tokens.cdoge,
    contractAddress: {
      56: '0xa6cC2841471c137D5aE032859d957c23551b763c', // CAKES 0.5
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '57275',
    isFinished: true,
  },
  {
    sousId: 108,
    stakingToken: tokens.olive,
    earningToken: tokens.czf,
    contractAddress: {
      56: '0x91F6f8c7Fc5e4d700Ba14C367e441557d867bdEd',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '10',
    isFinished: true,
  },
  {
    sousId: 107,
    stakingToken: tokens.olive,
    earningToken: tokens.antx,
    contractAddress: {
      56: '0xd66e955d6aa4e74d4f8843c5ddda0f5cc99c89dc',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '40506',
    isFinished: true,
  },
  {
    sousId: 106,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x72B57247aC93F1ED631610a3c1424223149A4ADE',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '270000',
    isFinished: true,
  },
  {
    sousId: 105,
    stakingToken: tokens.olive,
    earningToken: tokens.cbr,
    contractAddress: {
      56: '0xb1e6f93d5135a999dcced3d53218063d20535020',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '24',
    isFinished: true,
  },
  {
    sousId: 104,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0xe9ac3e7436ef89a451b59f148e648aa3cd0b8728',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '128',
    isFinished: true,
  },
  {
    sousId: 103,
    stakingToken: tokens.olive,
    earningToken: tokens.prho,
    contractAddress: {
      56: '0xedc377a63990fc73656379bd8fb5a7bdda207ecb', // DOT 0.19
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '90',
    isFinished: true,
  },
  {
    sousId: 102,
    stakingToken: tokens.olive,
    earningToken: tokens.chy,
    contractAddress: {
      56: '0xB3711c65F6C8e53efA21b151E29E3FbbC45Ba436',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.000467',
    isFinished: true,
  },
  {
    sousId: 101,
    stakingToken: tokens.olive,
    earningToken: tokens.game1,
    contractAddress: {
      56: '0x1d851e3891be7A04c4406AB657dC339801AF19C8',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.075',
    isFinished: true,
  },
  {
    sousId: 100,
    stakingToken: tokens.olive,
    earningToken: tokens.fdls,
    contractAddress: {
      56: '0x09b19de033358540a8abe56abfa6a4633291eb93',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1',
    isFinished: true,
  },
  {
    sousId: 99,
    stakingToken: tokens.olive,
    earningToken: tokens.cmon,
    contractAddress: {
      56: '0x4836B119DeECA151439524A44dfeF7A63f0a53A5',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '40',
    isFinished: true,
  },
  {
    sousId: 98,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x9678A29F51b1D45d9fdf176CafB7eE8d043Dab03',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '6000',
    isFinished: true,
  },
  {
    sousId: 96,
    stakingToken: tokens.olive,
    earningToken: tokens.supr,
    contractAddress: {
      56: '0xf67fb280b174bff0f593f3c894e8f3d3158b5500',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '170000',
    isFinished: true,
  },
  {
    sousId: 95,
    stakingToken: tokens.olive,
    earningToken: tokens.czusd,
    contractAddress: {
      56: '0xCe51324ABd5A9E8D3Ba6324B5A13C7B1c1318f6a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.005',
    isFinished: true,
  },
  {
    sousId: 94,
    stakingToken: tokens.olive,
    earningToken: tokens.godz,
    contractAddress: {
      56: '0x0874be045076cf8a78bb73400cbf6e2f17079711',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '20002278',
    isFinished: true,
  },
  {
    sousId: 93,
    stakingToken: tokens.olive,
    earningToken: tokens.seachain,
    contractAddress: {
      56: '0x60a24da9c6ede8eb22e8658d4bc23da88c4c10a1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '830',
    isFinished: true,
  },
  {
    sousId: 92,
    stakingToken: tokens.olive,
    earningToken: tokens.if1,
    contractAddress: {
      56: '0x386A1b3eD39E9220783d915303A66A888BF42B10',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '100000',
    isFinished: true,
  },
  {
    sousId: 91,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0xEc9B61Bf5608b1C9A47382c70aA82E702376c7FD',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '270000',
    isFinished: true,
  },
  {
    sousId: 90,
    stakingToken: tokens.olive,
    earningToken: tokens.okboomer,
    contractAddress: {
      56: '0xb7411d08780c4089f9c367dcb5ebb931384d3737',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1068321',
    isFinished: true,
  },
  {
    sousId: 89,
    stakingToken: tokens.olive,
    earningToken: tokens.dogedrinks,
    contractAddress: {
      56: '0x5146b35d3fb5eb17166616e23ed8e4bc69f81d62',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '4.66',
    isFinished: true,
  },
  {
    sousId: 88,
    stakingToken: tokens.olive,
    earningToken: tokens.flrs,
    contractAddress: {
      56: '0x57adb341e9399149c66f20e762ea6f35ccd0a59b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3489828',
    isFinished: true,
  },
  {
    sousId: 87,
    stakingToken: tokens.olive,
    earningToken: tokens.aquagoat2,
    contractAddress: {
      56: '0xbcae4f425191fbc85cbead8a2cd005e4da22eb05',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '413940',
    isFinished: true,
  },
  {
    sousId: 86,
    stakingToken: tokens.olive,
    earningToken: tokens.viva,
    contractAddress: {
      56: '0x39964D07ee998Dc43269e969480Ef2e5625fE1a2',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1000000',
    isFinished: true,
  },
  {
    sousId: 85,
    stakingToken: tokens.olive,
    earningToken: tokens.vdv,
    contractAddress: {
      56: '0x381e3a8b15b5a34d936213bf79d49cce82b03f3d',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '6.65',
    isFinished: true,
  },
  {
    sousId: 84,
    stakingToken: tokens.olive,
    earningToken: tokens.cdoge,
    contractAddress: {
      56: '0xfdb54ae61f9cc826f4e94400ef97363a48b2261f', // CAKES 0.42
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '42673',
    isFinished: true,
  },
  {
    sousId: 83,
    stakingToken: tokens.olive,
    earningToken: tokens.gem,
    contractAddress: {
      56: '0x005211e84d05b0b4898dF02ccE498A2f9d0f4c5c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0182',
    isFinished: true,
  },
  {
    sousId: 82,
    stakingToken: tokens.olive,
    earningToken: tokens.fswap,
    contractAddress: {
      56: '0xdFAB352E4FB20d688d41cabfA351061cf0128Ded',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.24',
    isFinished: true,
  },
  {
    sousId: 81,
    stakingToken: tokens.olive,
    earningToken: tokens.foxy,
    contractAddress: {
      56: '0x1c513001011d7b69070cFC19a2E93b98A42B883b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0125',
    isFinished: true,
  },
  {
    sousId: 80,
    stakingToken: tokens.olive,
    earningToken: tokens.supr,
    contractAddress: {
      56: '0x1ee861ed50cec2e408c62252cf7d235ee9c9e6e6',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '384000',
    isFinished: true,
  },
  {
    sousId: 79,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x7b09b52594E158A5AeC2B569b66DcC8D99940252',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '6002',
    isFinished: true,
  },
  {
    sousId: 78,
    stakingToken: tokens.olive,
    earningToken: tokens.ftk,
    contractAddress: {
      56: '0xcD090DBF8630007445c188d9135da9022De9dd6c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '97878629',
    isFinished: true,
  },
  {
    sousId: 77,
    stakingToken: tokens.olive,
    earningToken: tokens.godz,
    contractAddress: {
      56: '0xF9dC75Ec056F92a7F315BA04a6aa689F1cC4E4FC',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '28019858',
    isFinished: true,
  },
  {
    sousId: 76,
    stakingToken: tokens.olive,
    earningToken: tokens.cmon,
    contractAddress: {
      56: '0x2DF341314c100a42Ba46B7E88676ee0Fe32EEf65',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '14',
    isFinished: true,
  },
  {
    sousId: 75,
    stakingToken: tokens.olive,
    earningToken: tokens.xmine,
    contractAddress: {
      56: '0x8399eE8a6E2eE1F9a4f0370F4b10ef7291da21da', // CAKE 2.86
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '218',
    isFinished: true,
  },
  {
    sousId: 74,
    stakingToken: tokens.olive,
    earningToken: tokens.husky,
    contractAddress: {
      56: '0xf41c06c72493d1b484dabac0c50a3b03ad0f240a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '40000',
    isFinished: true,
  },
  {
    sousId: 73,
    stakingToken: tokens.olive,
    earningToken: tokens.czf,
    contractAddress: {
      56: '0xcc01b92fe2fb5c99880807060dedbfb095ff1e50',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '50',
    isFinished: true,
  },
  {
    sousId: 72,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0xE29194073669F9D4fb08e1230982073b0Bd14FAc',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '270000',
    isFinished: true,
  },
  {
    sousId: 71,
    stakingToken: tokens.olive,
    earningToken: tokens.charix,
    contractAddress: {
      56: '0x6D3759B59E0B231B4bb010306C4f4e3342ECe5ce',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1782438662',
    isFinished: true,
  },
  {
    sousId: 70,
    stakingToken: tokens.olive,
    earningToken: tokens.reddoge,
    contractAddress: {
      56: '0x8714A61F287C49A9049b8da860A8df103672A972',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.4',
    isFinished: true,
  },
  {
    sousId: 69,
    stakingToken: tokens.olive,
    earningToken: tokens.fg,
    contractAddress: {
      56: '0xF9f25F12d489099847d83BB41B5B5A4910154D3f',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '23292112',
    isFinished: true,
  },
  {
    sousId: 68,
    stakingToken: tokens.olive,
    earningToken: tokens.disc,
    contractAddress: {
      56: '0x0858A6b57CA53e938208025e9D313916a7B4E0Df',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0004275',
    isFinished: true,
  },
  {
    sousId: 67,
    stakingToken: tokens.olive,
    earningToken: tokens.shak,
    contractAddress: {
      56: '0x6F7a6FD50de6fB6A6dc5A215ab203fc42d1ce7F9',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '166',
    isFinished: true,
  },
  {
    sousId: 66,
    stakingToken: tokens.olive,
    earningToken: tokens.foxy,
    contractAddress: {
      56: '0xD4d2fF3603F7E5F329EfD31FD000bB72ccD1fa49',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.013333',
    isFinished: true,
  },
  {
    sousId: 65,
    stakingToken: tokens.olive,
    earningToken: tokens.bamboo,
    contractAddress: {
      56: '0xfDAf0713C53f2910137B776cBd7A1a316cC1748C',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.017',
    isFinished: true,
  },
  {
    sousId: 64,
    stakingToken: tokens.olive,
    earningToken: tokens.mgp,
    contractAddress: {
      56: '0x665B82b2e64B89996922Ba9fd8D8D0d5d1894DA9',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '5238720',
    isFinished: true,
  },
  {
    sousId: 63,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x08E4Fe02a003d981489127df649C37312CBa2b96',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3602',
    isFinished: true,
  },
  {
    sousId: 62,
    stakingToken: tokens.olive,
    earningToken: tokens.shiko,
    contractAddress: {
      56: '0x261d1a1c2e94eef3d4501661c9490b2a90e2560b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '192618236',
    isFinished: true,
  },
  {
    sousId: 61,
    stakingToken: tokens.olive,
    earningToken: tokens.husky,
    contractAddress: {
      56: '0x3D8478806F50800A6F8A921714bf98F369BE9039',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '524000',
    isFinished: true,
  },
  {
    sousId: 60,
    stakingToken: tokens.olive,
    earningToken: tokens.kana,
    contractAddress: {
      56: '0xf2c5b5043d784a6586E72a92440Bc52D257a6418',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '6',
    isFinished: true,
  },
  {
    sousId: 59,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x6A4FbC7Ff0eC330A132949745445C03336191189',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '270000',
    isFinished: true,
  },
  {
    sousId: 58,
    stakingToken: tokens.olive,
    earningToken: tokens.cdoge,
    contractAddress: {
      56: '0xDD7B7478abB2f5484085Eb28A90bAb68C1534Cc2', // CAKES 0.92
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '19582',
    isFinished: true,
  },
  {
    sousId: 57,
    stakingToken: tokens.olive,
    earningToken: tokens.hup,
    contractAddress: {
      56: '0xbbd1b08C2e0770a6De75bE45E7b5Afd3C96D8fD7',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3125312',
    isFinished: true,
  },
  {
    sousId: 56,
    stakingToken: tokens.olive,
    earningToken: tokens.mkc,
    contractAddress: {
      56: '0x75F335DAA032c9eE336A49B7161aE00ff6A66d09',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '7000',
    isFinished: true,
  },
  {
    sousId: 55,
    stakingToken: tokens.olive,
    earningToken: tokens.zar,
    contractAddress: {
      56: '0x4aCa5654746bb6f5e9Af7211cfBb33494b3F1C69',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1',
    isFinished: true,
  },
  {
    sousId: 54,
    stakingToken: tokens.olive,
    earningToken: tokens.gdoge,
    contractAddress: {
      56: '0xbB7D0A10882323EF0823537913662e91fF68eE31',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '81600000',
    isFinished: true,
  },
  {
    sousId: 53,
    stakingToken: tokens.olive,
    earningToken: tokens.gmpx,
    contractAddress: {
      56: '0x5eeaA9E961173cDF2fa589DDB0Ad4945702Fa34a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3378104',
    isFinished: true,
  },
  {
    sousId: 52,
    stakingToken: tokens.olive,
    earningToken: tokens.cmon,
    contractAddress: {
      56: '0x56e32a7523204d624f34872126289d51f68cc994',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '12',
    isFinished: true,
  },
  {
    sousId: 51,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x0540Db92c28850774e85BdF0A988639083162451',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '7647',
    isFinished: true,
  },
  {
    sousId: 50,
    stakingToken: tokens.olive,
    earningToken: tokens.shiko,
    contractAddress: {
      56: '0x050175cA3Ef96Ce514B0B3f9050c3137F6c46e4c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '144036393',
    isFinished: true,
  },
  {
    sousId: 49,
    stakingToken: tokens.olive,
    earningToken: tokens.poc,
    contractAddress: {
      56: '0xDED3968fDdA8D7609D0180383cE743A542E83033',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1816.7',
    isFinished: true,
  },
  {
    sousId: 48,
    stakingToken: tokens.olive,
    earningToken: tokens.safebond,
    contractAddress: {
      56: '0x9a3da8F314d1b2d473F332A8d805b930eFeEDc52',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '200000000',
    isFinished: true,
  },
  {
    sousId: 47,
    stakingToken: tokens.olive,
    earningToken: tokens.roto,
    contractAddress: {
      56: '0x45d019F2Aa5092331D7d2c2b6685Daa0b32B1b35',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '4772024',
    isFinished: true,
  },
  {
    sousId: 46,
    stakingToken: tokens.olive,
    earningToken: tokens.brigadeiro,
    contractAddress: {
      56: '0xfafB4FCFcfA868d3c5cb8F438C39A79DEc222Fa3',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '775817338612450',
    isFinished: true,
  },
  {
    sousId: 45,
    stakingToken: tokens.olive,
    earningToken: tokens.mkc,
    contractAddress: {
      56: '0x072237E3FB80f7064df06F355D05786C56A408e9',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1000',
    isFinished: true,
  },
  {
    sousId: 44,
    stakingToken: tokens.olive,
    earningToken: tokens.gapt,
    contractAddress: {
      56: '0xdBAFb2334B80C2Dd6A8FAC0B296F107C1C4c74c1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2000000',
    isFinished: true,
  },
  {
    sousId: 43,
    stakingToken: tokens.olive,
    earningToken: tokens.eggc,
    contractAddress: {
      56: '0x99bDAA611A65f9C92e63cCB1E541A2e357C2da46',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.136',
    isFinished: true,
  },
  {
    sousId: 42,
    stakingToken: tokens.olive,
    earningToken: tokens.blast,
    contractAddress: {
      56: '0x8ecF022c8E4A7D0e4D400501F9918CEcD621B7F1',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2.9',
    isFinished: true,
  },
  {
    sousId: 41,
    stakingToken: tokens.olive,
    earningToken: tokens.ltrbt,
    contractAddress: {
      56: '0xd656F7Cbf0f03E65fD9bad745655A9729D033037',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '9007801256833',
    isFinished: true,
  },
  {
    sousId: 40,
    stakingToken: tokens.olive,
    earningToken: tokens.laika,
    contractAddress: {
      56: '0x1f8c2e8f93c6452fc7bc4556769149824d6d8599',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '800282',
    isFinished: true,
  },
  {
    sousId: 39,
    stakingToken: tokens.olive,
    earningToken: tokens.fg,
    contractAddress: {
      56: '0x4C590bC54F47C38F16AE4141C606aE5b665ff293',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '24004270',
    isFinished: true,
  },
  {
    sousId: 38,
    stakingToken: tokens.olive,
    earningToken: tokens.gmpx,
    contractAddress: {
      56: '0x2C64613B9c73BD383364357007450e9813BC993E',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '276388',
    isFinished: true,
  },
  {
    sousId: 37,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x7EaD4c75399E783B81F8DCB611928cE2E7372D01',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '7644',
    isFinished: true,
  },
  {
    sousId: 36,
    stakingToken: tokens.olive,
    earningToken: tokens.disc,
    contractAddress: {
      56: '0x4e37913A0667313F5031886FcdAc02A6d3222241',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.0013',
    isFinished: true,
  },
  {
    sousId: 35,
    stakingToken: tokens.olive,
    earningToken: tokens.unft,
    contractAddress: {
      56: '0x8EBf5D18F9C8BEdFFA44213F105038567704D42c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3549472',
    isFinished: true,
  },
  {
    sousId: 34,
    stakingToken: tokens.olive,
    earningToken: tokens.tigz,
    contractAddress: {
      56: '0x1124234d391c6f92040df817B14Bb9fbA660cF1f',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '4000',
    isFinished: true,
  },
  {
    sousId: 32,
    stakingToken: tokens.olive,
    earningToken: tokens.glxc,
    contractAddress: {
      56: '0x2AE1da0529061FbFcb5B3DDd6ea19045a1794490',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '7912.69',
    isFinished: true,
  },
  {
    sousId: 31,
    stakingToken: tokens.olive,
    earningToken: tokens.sensi,
    contractAddress: {
      56: '0x8B215CBC73288fFA3E8cAA6914A9FA30c559A327',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '110909',
    isFinished: true,
  },
  {
    sousId: 30,
    stakingToken: tokens.olive,
    earningToken: tokens.unsafemoon,
    contractAddress: {
      56: '0x44De0d30394Ae6f895F0A5B185703345e8aDecf4',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '18355',
    isFinished: true,
  },
  {
    sousId: 29,
    stakingToken: tokens.olive,
    earningToken: tokens.wyvern,
    contractAddress: {
      56: '0xeb1f81B3c8217A579c0c37B54E0fE9F9793bA6b0',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '56.65',
    isFinished: true,
  },
  {
    sousId: 28,
    stakingToken: tokens.olive,
    earningToken: tokens.nmx,
    contractAddress: {
      56: '0x9d5a7A0116ECB02AAa81d8774121478738795e83',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.001',
    isFinished: true,
  },
  {
    sousId: 27,
    stakingToken: tokens.olive,
    earningToken: tokens.floki,
    contractAddress: {
      56: '0xB986bF52Dd9AB19038D0E9D7fF34bF3052057106',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '3062112',
    isFinished: true,
  },
  {
    sousId: 26,
    stakingToken: tokens.olive,
    earningToken: tokens.gap,
    contractAddress: {
      56: '0x0c754d094c3FEc2f4372026dfc15959C9bF7311E',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '552675537',
    isFinished: true,
  },
  {
    sousId: 25,
    stakingToken: tokens.olive,
    earningToken: tokens.inko,
    contractAddress: {
      56: '0xcb3f29b79e761e75646261F14Da1482ff207381d',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1000.26',
    isFinished: true,
  },
  {
    sousId: 24,
    stakingToken: tokens.olive,
    earningToken: tokens.coldkoala,
    contractAddress: {
      56: '0x0477328cB6e2441E25Adff3E4AE71187F1DD9e51',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '108048477877375',
    isFinished: true,
  },
  {
    sousId: 23,
    stakingToken: tokens.olive,
    earningToken: tokens.touranus,
    contractAddress: {
      56: '0xa80449ddbce767f270262f3101442723a771cf4c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '19270560',
    isFinished: true,
  },
  {
    sousId: 22,
    stakingToken: tokens.olive,
    earningToken: tokens.truck,
    contractAddress: {
      56: '0xcbd73b752fceae5813fe71febcf2f65a18c4f8d3',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1',
    isFinished: true,
  },
  {
    sousId: 21,
    stakingToken: tokens.olive,
    earningToken: tokens.charix,
    contractAddress: {
      56: '0xbae99550d881e24dea5d16107c6a60feadd6691c',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '350549261',
    isFinished: true,
  },
  {
    sousId: 20,
    stakingToken: tokens.olive,
    earningToken: tokens.ltrbt,
    contractAddress: {
      56: '0x2489A3342D0910c3E461def9eb36bBB04469cD01',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '25213989572530',
    isFinished: true,
  },
  {
    sousId: 33,
    stakingToken: tokens.olive,
    earningToken: tokens.gwspp,
    contractAddress: {
      56: '0x6e8C6D0c246e515CdE96151409B99Ecb33c47259',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.008',
    isFinished: true,
  },
  {
    sousId: 19,
    stakingToken: tokens.olive,
    earningToken: tokens.wspp,
    contractAddress: {
      56: '0x844d250b4bbf5458e0b87c40eef066aaccaa0b55',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '220000000',
    isFinished: true,
  },
  {
    sousId: 18,
    stakingToken: tokens.olive,
    earningToken: tokens.teamtrees,
    contractAddress: {
      56: '0xfcdfd259bb45a714ff860cb047022fef3f65125a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '10027474',
    isFinished: true,
  },
  {
    sousId: 17,
    stakingToken: tokens.olive,
    earningToken: tokens.beryl,
    contractAddress: {
      56: '0x3410c7ad7c0b60b02eba512ce54282b8de4a7067',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '69774',
    isFinished: true,
  },
  {
    sousId: 16,
    stakingToken: tokens.olive,
    earningToken: tokens.unft,
    contractAddress: {
      56: '0x2971f6356eb022410bbd2dd627131865bfaf1e5e',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1980278',
    isFinished: true,
  },
  {
    sousId: 15,
    stakingToken: tokens.olive,
    earningToken: tokens.brigadeiro,
    contractAddress: {
      56: '0xb0682d8918030a4bf1f803ddc1617ef872059a7b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '184826542687589',
    isFinished: true,
  },
  {
    sousId: 14,
    stakingToken: tokens.olive,
    earningToken: tokens.bubblegum,
    contractAddress: {
      56: '0x87fc4648ac363a592f921897b2e477cda8d08e43',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '5002',
    isFinished: true,
  },
  {
    sousId: 12,
    stakingToken: tokens.olive,
    earningToken: tokens.mkc,
    contractAddress: {
      56: '0x2d542d2a6f1cb12e3774faa3bdfd5dfa7cf5612b',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '1999',
    isFinished: true,
  },
  {
    sousId: 11,
    stakingToken: tokens.olive,
    earningToken: tokens.moonrabbit,
    contractAddress: {
      56: '0x7a39c3a3d5fC3B116B2AEBd1723B3443142a567B',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '753941692',
    isFinished: true,
  },
  {
    sousId: 10,
    stakingToken: tokens.olive,
    earningToken: tokens.gapt,
    contractAddress: {
      56: '0x2e2ddb13c21bbac8b89e33fa4b3430bb15357b92',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '2000000',
    isFinished: true,
  },
  {
    sousId: 9,
    stakingToken: tokens.olive,
    earningToken: tokens.leopard,
    contractAddress: {
      56: '0x9ed219a534066b295a98ce3439fd1699d0d15c71',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '420974270',
    isFinished: true,
  },
  {
    sousId: 8,
    stakingToken: tokens.olive,
    earningToken: tokens.fg,
    contractAddress: {
      56: '0x280F2Cf2590fe5A1fCeB567A860f0af217045648',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '100002836',
    isFinished: true,
  },
  {
    sousId: 5,
    stakingToken: tokens.olive,
    earningToken: tokens.unsafemoon,
    contractAddress: {
      56: '0x0b992f75068816dB2EB47C9E6Fe840ba583cF847',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '32086',
    isFinished: true,
  },

  // FINISHED
  {
    sousId: 1,
    stakingToken: tokens.olive,
    earningToken: tokens.spore,
    contractAddress: {
      56: '0x97D569AE323a174DcAB9b5B9fa8E6CFAD0cA931a',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '794901016',
    isFinished: true,
  },
  {
    sousId: 2,
    stakingToken: tokens.olive,
    earningToken: tokens.spore,
    contractAddress: {
      56: '0x771F5d5B8b7560C779816cB425981D588CD8C518',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '20562672',
    isFinished: true,
  },
  {
    sousId: 3,
    stakingToken: tokens.olive,
    earningToken: tokens.doge,
    contractAddress: {
      56: '0xAe817479AA53e9C0acf9aCfD8Ec167613d4B120f',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 2,
    tokenPerBlock: '0.04',
    isFinished: true,
  },
  {
    sousId: 4,
    stakingToken: tokens.olive,
    earningToken: tokens.aquagoat,
    contractAddress: {
      56: '0xC31BA36dc50a360E51de26BdbF5fe741a383E9f3',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '75760900',
    isFinished: true,
  },
  {
    sousId: 6,
    stakingToken: tokens.olive,
    earningToken: tokens.com,
    contractAddress: {
      56: '0x74c699E897EbB95E630f974d97615b6c50896d21',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.1',
    isFinished: true,
  },
  {
    sousId: 7,
    stakingToken: tokens.olive,
    earningToken: tokens.zero,
    contractAddress: {
      56: '0x7053884c1FE620e55dfe8a151e0A41175009fEb2',
    },
    poolCategory: PoolCategory.COMMUNITY,
    harvest: true,
    sortOrder: 1,
    tokenPerBlock: '0.05',
    isFinished: true,
  },

  // {
  //   sousId: 92, // NO tokens, 9999 time
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.doge0,
  //   contractAddress: {
  //     56: '0x3500De9A3825b374fA74B86B70f0755f55f3Ce73',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // {
  //   sousId: 76,
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.buff,
  //   contractAddress: {
  //     56: '0x49Ac659118Dd114625Db6d299e5Bfa804e7Dfb4E',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // { // DEVS didn't send TOKENS
  //   sousId: 100,
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.lpup,
  //   contractAddress: {
  //     97: '',
  //     56: '0x310cb749f8907270361e7d2095dcda403470ce0b',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // { // DEVS didn't send TOKENS
  //   sousId: 101,
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.aquashib,
  //   contractAddress: {
  //     97: '',
  //     56: '0xF5659681C02A4E975FDC86b2edad6020bccA1A2e',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '20',
  //   isFinished: false,
  // },
  // {
  //   sousId: 110, // BIG TIMER
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.laika,
  //   contractAddress: {
  //     56: '0xcdb6B46ADd3E93A724739D67578c86bBa920D104',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // {
  //   sousId: 109, // BIG TIMER
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.ldc,
  //   contractAddress: {
  //     56: '0x54d85339F5C4292b71F7699D9A75A110eF169e6D',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
  // {
  //   sousId: 108, // BIG TIMER
  //   stakingToken: tokens.olive,
  //   earningToken: tokens.dut,
  //   contractAddress: {
  //     56: '0xcba7483abc9254dfe66997ff6445c51c7c7dfac3',
  //   },
  //   poolCategory: PoolCategory.COMMUNITY,
  //   harvest: true,
  //   sortOrder: 1,
  //   tokenPerBlock: '1',
  //   isFinished: false,
  // },
]

export default pools
