import tokens from './tokens'

import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 10,
    lpSymbol: 'OLIVE-BNB LP v2',
    lpAddresses: {
      56: '0x51123Dd7BF791929BBfA75E913f7632A0eF1B82c',
    },
    token: tokens.olive,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 11,
    lpSymbol: 'OLIVE-BUSD LP v2',
    lpAddresses: {
      56: '0x16B875A1AF5DaA94f91d808D6985783bFe058295',
    },
    token: tokens.olive,
    quoteToken: tokens.busd,
  },
  // {
  //   pid: 131,
  //   lpSymbol: 'BRIS',
  //   lpAddresses: {
  //     56: '0x88C945170267BA9b7959E3f07706846890F79924',
  //   },
  //   quoteLpAddress: {
  //     56: '0x155C3DaD5CB24591b17f69728b63A127Be9174ab', // LP URL
  //   },
  //   token: tokens.bris,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 130,
    lpSymbol: 'Afterburner-BNB LP',
    lpAddresses: {
      56: '0x41057CcD850949c89D37B1E9d980fD971f86646A',
    },
    token: tokens.afterburner,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 129,
    lpSymbol: 'KEY',
    lpAddresses: {
      56: '0x07b1681C082039551952bDee4A505cecC2FF4998',
    },
    quoteLpAddress: {
      56: '0x42633f9f6fC4D44F165Be67d1C825CE0f0C86BaB', // LP URL
    },
    token: tokens.key,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 128,
    lpSymbol: 'SMILE-BNB LP',
    lpAddresses: {
      56: '0x2ddd19c5CfBCCDF44176b6Bf3456BB65D6DCDe84',
    },
    token: tokens.smile,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 127,
    lpSymbol: 'ZONO',
    lpAddresses: {
      56: '0xAc97796B45F9627e16da9C93e608579ceEb410a4',
    },
    quoteLpAddress: {
      56: '0x84D51E05eCbde468F3C7671550650c1Dafc70d46', // LP URL
    },
    token: tokens.zono,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 126,
    lpSymbol: 'FLOKI-BNB LP',
    lpAddresses: {
      56: '0x5bBBB4175495162Fe468A904B910159E863e06ef',
    },
    token: tokens.flokiMigrated,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 124,
    lpSymbol: 'Daima-BNB*APES LP',
    lpAddresses: {
      56: '0x6B4c9A46b741EA498Fc93504fdAB255cBC83dcCA',
    },
    lpUrl: 'https://app.apeswap.finance/add/ETH/0xba85367ec9339d03d77f389f5be2a2a21e4dcfea',
    infoUrl: 'https://info.apeswap.finance/pair/0x6B4c9A46b741EA498Fc93504fdAB255cBC83dcCA',
    token: tokens.daima,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 123,
    lpSymbol: 'YUKLA',
    lpAddresses: {
      56: '0x878f46773e99f7dd308ebf0a3a331e7ebbb24b25',
    },
    quoteLpAddress: {
      56: '0x3acabb2dfD851B2D7938067f50869A6fD6768aA9', // LP URL
    },
    lpUrl: 'https://exchange.yuklaswap.com/#/add/ETH/0x878f46773e99f7Dd308eBF0a3A331e7EBbb24B25',
    token: tokens.yukla,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 104,
    lpSymbol: 'FRT',
    lpAddresses: {
      56: '0xd51237a6f3219d186f0c8d8dd957b1bcb3ce5d48',
    },
    quoteLpAddress: {
      56: '0x2f82C2c7cA7C8f548e0c92C9dc7E8Ee98c658e9D', // LP URL
    },
    lpUrl: 'https://olive.cash/add/BNB/0x9eC132c19f2c35272DE3f81a75D674752D952DA8',
    token: tokens.frt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 122,
    lpSymbol: 'WINGS-BNB*JS LP',
    lpAddresses: {
      56: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    },
    lpUrl: 'https://exchange.jetswap.finance/#/add/BNB/0x0487b824c8261462F88940f97053E65bDb498446',
    infoUrl: 'https://info.jetswap.finance/pair/0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
    token: tokens.wings,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 80,
    lpSymbol: 'SeaChain-BNB*APES LP',
    lpAddresses: {
      56: '0x74b1C9Fe9b0CB0a395DC76aCb00dE288939682a1',
    },
    lpUrl: 'https://app.apeswap.finance/add/ETH/0x36b24b2f78725495e858af9e72f7df69dade3dca',
    infoUrl: 'https://info.apeswap.finance/pair/0x74b1C9Fe9b0CB0a395DC76aCb00dE288939682a1',
    token: tokens.seachain,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 79,
    lpSymbol: 'IF1-BNB LP',
    lpAddresses: {
      56: '0x4b5676c8775f035405c817e11c2ecb3ce5e5ef90',
    },
    token: tokens.if1,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 24,
    lpSymbol: 'BUBBLEGUM-BNB LP',
    lpAddresses: {
      56: '0xac645caa8feB3758a3f6E6fB6D629F427C53E744',
    },
    token: tokens.bubblegum,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 41,
    lpSymbol: 'SENSI-BNB LP',
    lpAddresses: {
      56: '0x5B75607f3f38a335671C16A897664e3023bcc82c',
    },
    token: tokens.sensi,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 67,
    lpSymbol: 'XMINE-BNB LP',
    lpAddresses: {
      56: '0x62bd4aCb9a09bD1d8953Eb2371D7C86D3D3eC70d',
    },
    token: tokens.xmine,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 121,
    lpSymbol: 'FNX-BNB LP',
    lpAddresses: {
      56: '0xdff1f748218d9e2c21ae45900097cb67a6199f0d',
    },
    token: tokens.fnx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 103,
    lpSymbol: 'LORY',
    lpAddresses: {
      56: '0xcd5d75dbe75449a9021b6c570a41959eb571c751',
    },
    quoteLpAddress: {
      56: '0x3d0c2ee0156675b90bc41e5559970415a20414f5', // LP URL
    },
    lpUrl: 'https://app.apeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xcD5D75Dbe75449A9021B6C570a41959eB571C751',
    token: tokens.lory,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 120,
    lpSymbol: 'MERD',
    lpAddresses: {
      56: '0xa8d3f6d1dea173a262f7ddb74a15c15858cb0113',
    },
    quoteLpAddress: {
      56: '0x05B778b111D84eAE59EDfdd3c334776E2eB55604',
    },
    lpUrl: 'https://olive.cash/add/BNB/0xa8d3f6d1dea173a262f7ddb74a15c15858cb0113',
    token: tokens.merd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 125,
    lpSymbol: 'YUKLA-BNB*YUKLA LP',
    lpAddresses: {
      56: '0x3acabb2dfD851B2D7938067f50869A6fD6768aA9',
    },
    lpUrl: 'https://exchange.yuklaswap.com/#/add/ETH/0x878f46773e99f7Dd308eBF0a3A331e7EBbb24B25',
    infoUrl: 'https://info.yuklaswap.com/pair/0x3acabb2dfD851B2D7938067f50869A6fD6768aA9',
    isHidden: true,
    token: tokens.yukla,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 119,
    lpSymbol: 'FLB-BNB LP',
    lpAddresses: {
      56: '0x04C493e211Bf532755345328Cb28451A42Ff2a49',
    },
    token: tokens.flb,
    quoteToken: tokens.wbnb,
    isHidden: true,
  },
  {
    pid: 118,
    lpSymbol: 'JADE-BUSD LP',
    lpAddresses: {
      56: '0x46503d91D7a41FCbDC250E84ceE9D457d082D7b4',
    },
    token: tokens.jade,
    quoteToken: tokens.busd,
    isHidden: true,
  },
  // {
  //   pid: 117,
  //   lpSymbol: 'MAME-BNB LP',
  //   lpAddresses: {
  //     56: '0xbA8843c353cefbd20835B675E09CEDB2e787a6a9',
  //   },
  //   token: tokens.mame,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 53,
    lpSymbol: 'CMON-BNB LP',
    lpAddresses: {
      56: '0x0a6d62D2C437ffba6391EA6296B7FFCCC9fbc891',
    },
    token: tokens.cmon,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 116,
    lpSymbol: 'CHIMPY',
    lpAddresses: {
      56: '0x026a3918A6D6F7B7b1b6887B8B650143b5d04732',
    },
    quoteLpAddress: {
      56: '0x1ACe884443907bf2409E38a30378b3F2570F1E60',
    },
    lpUrl: 'https://chimpyswap.com/add/BNB/0x026a3918A6D6F7B7b1b6887B8B650143b5d04732',
    token: tokens.chimpy,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 115,
    lpSymbol: 'GUT',
    lpAddresses: {
      56: '0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
    },
    quoteLpAddress: {
      56: '0x3a42841F6e31D83F132743f5DfC1743DB3eD8bBf',
    },
    lpUrl: 'https://guitarswap.exchange/add/BNB/0xb6bA8c98021C31A02DD65e9bE97729EbA859d4E2',
    token: tokens.gut,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 114,
    lpSymbol: 'SQUID',
    lpAddresses: {
      56: '0xAE61e7dc989718E700C046a2483e93513eDCA484',
    },
    quoteLpAddress: {
      56: '0x2e0484D3684701dC032f29cce59c785A5837B34E',
    },
    lpUrl: 'https://autoshark.finance/add/BNB/0xAE61e7dc989718E700C046a2483e93513eDCA484',
    token: tokens.squid,
    quoteToken: tokens.wbnb,
    isHidden: true,
  },
  {
    pid: 113,
    lpSymbol: 'JIZZD-BNB LP',
    lpAddresses: {
      56: '0xE20ddb53C209f5f6ce5454eE624cd6427C6809f7',
    },
    token: tokens.jizzd,
    quoteToken: tokens.wbnb,
    isHidden: true,
  },
  {
    pid: 112,
    lpSymbol: 'RSHIB-BNB LP',
    lpAddresses: {
      56: '0x0b5731299F599A7C5C6eB79bD523e793dF4C5D2a',
    },
    token: tokens.rshib,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 111,
    lpSymbol: 'CORIS',
    lpAddresses: {
      56: '0x2a2cd8b1f69eb9dda5d703b3498d97080c2f194f',
    },
    quoteLpAddress: {
      56: '0x1881bd6aBA086da0C5cfEd7247F216deA50E38Ed', // LP URL
    },
    lpUrl: 'https://olive.cash/add/BNB/0x2a2cd8b1f69eb9dda5d703b3498d97080c2f194f',
    token: tokens.coris,
    quoteToken: tokens.wbnb,
  },
  // {
  //   pid: 101,
  //   lpSymbol: 'REDBUFF',  // Reflexive + Our MS was Whitelisted!
  //   lpAddresses: {
  //     56: '0x9eC132c19f2c35272DE3f81a75D674752D952DA8',
  //   },
  //   quoteLpAddress: {
  //     56: '0x31460CF8Ac5E5FB8B53545b22201441fb936b942', // LP URL
  //   },
  //   lpUrl: 'https://olive.cash/add/BNB/0x9eC132c19f2c35272DE3f81a75D674752D952DA8',
  //   token: tokens.redbuff,
  //   quoteToken: tokens.wbnb,
  // },
  // Common farms

  {
    pid: 94,
    lpSymbol: 'CGU-USDT LP',
    lpAddresses: {
      56: '0xCFbD5b7583Ae717D6890a01F957a7439Eda860fA',
    },
    token: tokens.cgu,
    quoteToken: tokens.usdt,
  },
  {
    pid: 58,
    lpSymbol: 'CDOGE-BNB LP',
    lpAddresses: {
      56: '0x7c54342e2631580E23c56e9573E040b5589ab872',
    },
    token: tokens.cdoge,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 83,
    lpSymbol: 'GAME1-USDT LP',
    lpAddresses: {
      56: '0x29E63966792D692ef405D897BD716c30E4e54944',
    },
    lpUrl: 'https://exchange.babyswap.finance/#/add/0x0e52d24c87a5ca4f37e3ee5e16ef5913fb0cceeb/0x55d398326f99059fF775485246999027B3197955',
    infoUrl: 'https://babyswap.info/pair/0x29e63966792d692ef405d897bd716c30e4e54944',
    token: tokens.game1,
    quoteToken: tokens.usdt,
  },
  {
    pid: 107,
    lpSymbol: 'STKD-BNB LP',
    lpAddresses: {
      56: '0x1431aA87967533A3AB587d701F8Ba6C2b8565f77',
    },
    token: tokens.stkd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 109,
    lpSymbol: 'CGS-BNB LP',
    lpAddresses: {
      56: '0x5a10c72cc54f8966d29238c2112d33ce678c7bc1',
    },
    token: tokens.cgs,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 108,
    lpSymbol: 'SITX-USDT LP',
    lpAddresses: {
      56: '0x58ab6ea8baf96c50aa23f978c3993e5e263ff156',
    },
    token: tokens.sitx,
    quoteToken: tokens.usdt,
    isHidden: true,
  },
  {
    pid: 106,
    lpSymbol: 'BBRB-BNB LP',
    lpAddresses: {
      56: '0x33DF4CE48C204E0BCAE0f66a9f14eF35939D43a7',
    },
    token: tokens.bbrb,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 99,
    lpSymbol: 'REDBUFF-BNB LP',
    lpAddresses: {
      56: '0x31460CF8Ac5E5FB8B53545b22201441fb936b942',
    },
    token: tokens.redbuff,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 98,
    lpSymbol: 'BUFF-BNB LP',
    isHidden: true,
    lpAddresses: {
      56: '0xfdCF6F480838e7b12f091dACa213Ef571782b79C',
    },
    token: tokens.buff,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 97,
    lpSymbol: 'CROX-BNB LP',
    lpAddresses: {
      56: '0xE38e899cc99ddeA9737e06f0A22046d0CA904D70',
    },
    token: tokens.crox,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 96,
    lpSymbol: 'LKS-BNB LP',
    lpAddresses: {
      56: '0xc51C70CccaB78EFC56CCC59405816a14E8254d68',
    },
    token: tokens.lks,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 71,
    lpSymbol: 'FSWAP-BNB LP',
    lpAddresses: {
      56: '0x3F87C4d9472E51C5571B76dB73c6f9eF416Fc1d1',
    },
    token: tokens.fswap,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 93,
    lpSymbol: 'CFF-BNB LP',
    lpAddresses: {
      56: '0xe5406e63d412ab4a2c10538771329867d3a1094a',
    },
    token: tokens.cff,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 70,
    lpSymbol: 'SUPR-BNB LP',
    lpAddresses: {
      56: '0xEf47e66684327d00241d47a1B21882A8b3B66169',
    },
    token: tokens.supr,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 92,
    lpSymbol: 'AMPLE-BNB LP',
    lpAddresses: {
      56: '0x057f4e9cDcDD09cde25Fa3DcAaF4166eB02F4F0e',
    },
    token: tokens.ample,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 90,
    lpSymbol: 'QUA-BNB LP',
    lpAddresses: {
      56: '0x33565397280cef418fee0dc2f8ef58372adf0b2f',
    },
    token: tokens.qua,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 87,
    lpSymbol: 'ANTX-BNB LP',
    lpAddresses: {
      56: '0x710d73B2f962a77D5dCD8A0654d8f45D9bE3141d',
    },
    token: tokens.antx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 66,
    lpSymbol: 'CZF-BNB LP',
    lpAddresses: {
      56: '0xeF8e8CfADC0b634b6d0065080a69F139159a17dE',
    },
    token: tokens.czf,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 86,
    lpSymbol: 'CBR-BNB LP',
    lpAddresses: {
      56: '0x4c31Ec1A05CBBCC39f2b6F3aA9F3324ABd6c3172',
    },
    token: tokens.cbr,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 85,
    lpSymbol: 'PRHO-BNB LP',
    lpAddresses: {
      56: '0x5de73f573Bd30fA97E174e442227695996158ed6',
    },
    token: tokens.prho,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 81,
    lpSymbol: 'CZUSD-BUSD LP',
    lpAddresses: {
      56: '0xd7C6Fc00FAe64cb7D242186BFD21e31C5b175671',
    },
    token: tokens.czusd,
    quoteToken: tokens.busd,
  },
  {
    pid: 76,
    lpSymbol: 'FLRS-BNB LP',
    lpAddresses: {
      56: '0x7ee5CcdF9568b08fA7494C898d98c67E12c1E1b6',
    },
    token: tokens.flrs,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 68,
    lpSymbol: 'GODZ-BNB LP',
    lpAddresses: {
      56: '0x6E226BbcDcF83aE597970aBe44BEEC1550c16c9a',
    },
    token: tokens.godz,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 75,
    lpSymbol: 'AQUAGOAT-BNB LP',
    lpAddresses: {
      56: '0xd140E2D46aA11E6F59def76f5a82f9644775aA94',
    },
    token: tokens.aquagoat2,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 65,
    lpSymbol: 'REDDOGE-BNB LP',
    lpAddresses: {
      56: '0x43b00224C9B35a9786Cb63A70691844D52017dE4',
    },
    token: tokens.reddoge,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 69,
    lpSymbol: 'FTK-BNB LP',
    lpAddresses: {
      56: '0xfB3739dA1fcEb97045ddF626dDcA4C3326fabD63',
    },
    token: tokens.ftk,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 62,
    lpSymbol: 'BAMBOO-BUSD LP',
    lpAddresses: {
      56: '0x79074b325C18aF9456F31E188D689357039BC30a',
    },
    token: tokens.bamboo,
    quoteToken: tokens.busd,
  },
  {
    pid: 56,
    lpSymbol: 'MKC-BNB*APES LP',
    lpAddresses: {
      56: '0x77AcD18484430203212dc67C9b5362d0ABACE8de',
    },
    lpUrl: 'https://app.apeswap.finance/add/ETH/0x92cc2c999aDE41C71D1c8136D5b57d12564E045f',
    infoUrl: 'https://info.apeswap.finance/pair/0x77AcD18484430203212dc67C9b5362d0ABACE8de',
    token: tokens.mkc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 60,
    lpSymbol: 'HUSKY-BNB LP',
    lpAddresses: {
      56: '0xD502E9D30F3A17dB154df0d40F36d606736f984d',
    },
    token: tokens.husky,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 59,
    lpSymbol: 'KANA-BNB LP',
    lpAddresses: {
      56: '0x1Fac7Bb7D03de3228215501B9A966D6b6ea17A75',
    },
    token: tokens.kana,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 12,
    lpSymbol: 'SPORE-BNB LP',
    lpAddresses: {
      56: '0x4aA8F0ef7dd950e260d5EeaF50A1D796D0cefd2f',
    },
    token: tokens.spore,
    quoteToken: tokens.wbnb,
  },

  /**
   * Not active Farms
   */
  {
    pid: 23,
    lpSymbol: 'MKC-BNB LP',
    lpAddresses: {
      56: '0x4931c2c8e804a86282EF8559Df78449008874905',
    },
    token: tokens.mkc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 57,
    lpSymbol: 'HUP-BNB LP',
    lpAddresses: {
      56: '0x394fF2d15E5560C5fFaF4BF48781A50c98315dCe',
    },
    token: tokens.hup,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 54,
    lpSymbol: 'GDOGE-BNB LP',
    lpAddresses: {
      56: '0x694D146f5353939A0Cb8791dCEC08f3c535b168e',
    },
    token: tokens.gdoge,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 44,
    lpSymbol: 'DISC-BNB LP',
    lpAddresses: {
      56: '0x32B8B140080D4D5059d364a26Eb75014E6BE4648',
    },
    token: tokens.disc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 31,
    lpSymbol: 'CHARIX-BNB LP',
    lpAddresses: {
      56: '0x2044AC69287440715296DAA933f01bc10Df7FBE0',
    },
    token: tokens.charix,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 29,
    lpSymbol: 'WSPP-BNB LP',
    lpAddresses: {
      56: '0x31FdF4333d2edCAdCebd25edd7DaBCB73B27e851',
    },
    token: tokens.wspp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 18,
    lpSymbol: 'FG-BNB LP',
    lpAddresses: {
      56: '0x746cdbb1bfBB19e4897Aee3a5312De79C40FD5bB',
    },
    token: tokens.fg,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 51,
    lpSymbol: 'POC-BNB LP',
    lpAddresses: {
      56: '0x74eD370d5116305c513720410Ca42D94Ab4FecAA',
    },
    token: tokens.poc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 49,
    lpSymbol: 'ROTO-BNB LP',
    lpAddresses: {
      56: '0xdFD25937062E14015eCF1Ce543214F1A95bb654a',
    },
    token: tokens.roto,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 52,
    lpSymbol: 'SHIKO-BNB LP',
    lpAddresses: {
      56: '0xEe78e0aA04a07A3a948b0ebC4C8966f6c1e748C7',
    },
    token: tokens.shiko,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 55,
    lpSymbol: 'ZAR-BNB LP',
    lpAddresses: {
      56: '0xFD6e140506E35Ba29A44A928D92753BA24eAAf1b',
    },
    token: tokens.zar,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 45,
    lpSymbol: 'GMPX-BNB LP',
    lpAddresses: {
      56: '0xbAE50a452300c9097093Ab5D3102Ad697fC7219b',
    },
    token: tokens.gmpx,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 42,
    lpSymbol: 'GLXC-BNB LP',
    lpAddresses: {
      56: '0xfeC7c1279Bd6D9596B128d1573587Ff865919AA8',
    },
    token: tokens.glxc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 15,
    lpSymbol: 'UNSAFEMOON-BNB LP',
    lpAddresses: {
      56: '0x37F1435C6F4EF3DdfcdEa3091c871dAC7e077195',
    },
    token: tokens.unsafemoon,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 46,
    lpSymbol: 'LAIKA-BNB LP',
    lpAddresses: {
      56: '0xa2881389D523B26cdAd546BfAfB0ae09DA253405',
    },
    token: tokens.laika,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 43,
    lpSymbol: 'TIGZ-BNB LP',
    lpAddresses: {
      56: '0x0C054eE39Aefda1b281eE5b156734DC83067abaE',
    },
    token: tokens.tigz,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 26,
    lpSymbol: 'UNFT-BNB LP',
    lpAddresses: {
      56: '0xfA7941eA3b5961B4b1d713cC61e68351E4A4AcF4',
    },
    token: tokens.unft,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 48,
    lpSymbol: 'EGGC-BNB LP',
    lpAddresses: {
      56: '0x02Fe6DBEEfC6AA4b3ec86E7eF2574b060EA64127',
    },
    token: tokens.eggc,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 34,
    lpSymbol: 'ColdKoala-BNB LP',
    lpAddresses: {
      56: '0xFfc291eDD8ecEF5BDc33384406581447022bC55e',
    },
    token: tokens.coldkoala,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 30,
    lpSymbol: 'LTRBT-BNB LP',
    lpAddresses: {
      56: '0x9d6d4d01EC30801e60589a24AB3D8ca4B2aFc188',
    },
    token: tokens.ltrbt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 37,
    lpSymbol: 'FLOKI-BNB LP',
    lpAddresses: {
      56: '0x5cB760Abe650E2aC1EBe2703f8077DeC349d839a',
    },
    token: tokens.floki,
    quoteToken: tokens.wbnb,
  },
  // {
  //   pid: 36,
  //   lpSymbol: 'GAP-BNB LP',
  //   lpAddresses: {
  //     56: '0x8C6f2CC37d85A35E27b2A41EA99Bb54Ae0c2adEA',
  //   },
  //   token: tokens.gap,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 72,
  //   lpSymbol: 'GEM-BNB LP',
  //   lpAddresses: {
  //     56: '0x8947C3a24d51E104DDBead2d864efAe7920B30E5',
  //   },
  //   token: tokens.gem,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 33,
  //   lpSymbol: 'ToUranus-BNB LP',
  //   lpAddresses: {
  //     56: '0xff23DdAa7dA7b2101A8023BF1b7B39Fe0777966B',
  //   },
  //   token: tokens.touranus,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 110,
  //   lpSymbol: 'SHIO-BNB LP',
  //   lpAddresses: {
  //     56: '0xBEbF0950787c3A102CD80432EF670B24BCbb963F',
  //   },
  //   token: tokens.shio,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 105,
  //   lpSymbol: 'HERO-BNB LP',
  //   lpAddresses: {
  //     56: '0x1bA7F3882Edc3b12745768c2a68684c4B1fAC55B',
  //   },
  //   token: tokens.hero,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 95,
  //   lpSymbol: 'FCF-BNB LP',
  //   lpAddresses: {
  //     56: '0xc58a80cf11d1cbbae4fc1c2fa9244f517676ab3d',
  //   },
  //   token: tokens.fcf,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 64,
  //   lpSymbol: 'SHAK-BUSD LP',
  //   lpAddresses: {
  //     56: '0x1845Bbb0682156BC622DDaC535cDBE61071aE9E7',
  //   },
  //   token: tokens.shak,
  //   quoteToken: tokens.busd,
  // },
  // {
  //   pid: 88,
  //   lpSymbol: 'BALPAC-BNB LP',
  //   lpAddresses: {
  //     56: '0x9753ce24d009AD6810B6e51fbf7344bcF0508658',
  //   },
  //   token: tokens.balpac,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 84,
  //   lpSymbol: 'CHY-BNB LP',
  //   lpAddresses: {
  //     56: '0x62C91836Ec825C3593a40b15fA6a24f555358956',
  //   },
  //   token: tokens.chy,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 73,
  //   lpSymbol: 'VDV-BNB LP',
  //   lpAddresses: {
  //     56: '0x5d411b470C5F1F2194dcFB6f6862bE4C34787058',
  //   },
  //   token: tokens.vdv,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 78,
  //   lpSymbol: 'OKBOOMER-BNB LP',
  //   lpAddresses: {
  //     56: '0x763CC7Bb7313fCb6EE25944D4998E72db20846b5',
  //   },
  //   token: tokens.okboomer,
  //   quoteToken: tokens.wbnb,
  // },
  // {
  //   pid: 102,
  //   lpSymbol: 'CHT-BNB LP',
  //   lpAddresses: {
  //     56: '0x27f3962b74bA5479c2C4C7a6a3c2eeCb3497a10e',
  //   },
  //   token: tokens.cht,
  //   quoteToken: tokens.wbnb,
  // },
  {
    pid: 35,
    lpSymbol: 'InuNeko-BNB LP',
    lpAddresses: {
      56: '0x3bf787E6c6F758ec42cC04328B872fc12Eb25EAF',
    },
    token: tokens.inko,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 25,
    lpSymbol: 'BRIGADEIRO-BNB LP',
    lpAddresses: {
      56: '0x87a7fF1f916E1B9449934acAb96c8560Dd258eaB',
    },
    token: tokens.brigadeiro,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 20,
    lpSymbol: 'GAPT-BNB LP',
    lpAddresses: {
      56: '0xd1D9c0a8610B946e681Fd98E22815B570C189aa8',
    },
    token: tokens.gapt,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 28,
    lpSymbol: 'TEAMTREES-BNB LP',
    lpAddresses: {
      56: '0x14227FA7FA281baf8Ad98D1318fa218271f1c235',
    },
    token: tokens.teamtrees,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 27,
    lpSymbol: 'BERYL-BNB LP',
    lpAddresses: {
      56: '0xC4D367FC6C04BD6d9D1a5f1860B96579eC32E5fD',
    },
    token: tokens.beryl,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 22,
    lpSymbol: 'MOONRABBIT-BNB LP',
    lpAddresses: {
      56: '0x4a0197A5Dc2969cF795bC1de44FACEdCd4b6f439',
    },
    token: tokens.moonrabbit,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 19,
    lpSymbol: 'LEOPARD-BNB LP',
    lpAddresses: {
      56: '0x01839856993B0883d8AD2f9DDC5450cfBe551E8a',
    },
    token: tokens.leopard,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 14,
    lpSymbol: 'AQUAGOAT-BNB LP',
    lpAddresses: {
      56: '0xb241B05F3066C3DC34Eba34745d9950A84f1Ce0B',
    },
    token: tokens.aquagoat,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 2,
    lpSymbol: 'OLIVE-BUSD LP',
    lpAddresses: {
      56: '0x617724974218A18769020A70162165A539c07E8a',
    },
    token: tokens.olive,
    quoteToken: tokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'OLIVE-BNB LP',
    lpAddresses: {
      56: '0x8E9A1C74f8b194933f1B520CE58Eb3405ea68008',
    },
    token: tokens.olive,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 4,
    lpSymbol: 'BUSD-BNB LP',
    lpAddresses: {
      56: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 50,
    lpSymbol: 'SAFEBOND-BNB LP',
    isHidden: true,
    lpAddresses: {
      56: '0x3CfB2C6c22FD5b74978eA8B23aa9f16055dA57Cb',
    },
    token: tokens.safebond,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 61,
    lpSymbol: 'MGP-BNB LP',
    isHidden: true,
    lpAddresses: {
      56: '0xD26B66AFC03C1F5A1deB2002BF11eE677c4a33D4',
    },
    token: tokens.mgp,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 74,
    lpSymbol: 'VIVA-BNB LP',
    isHidden: true,
    lpAddresses: {
      56: '0xD55496B1d84dfd4614c89ae90fa9835a4B907DEf',
    },
    token: tokens.viva,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 77,
    lpSymbol: 'DOGEDRINKS-BNB LP',
    isHidden: true,
    lpAddresses: {
      56: '0x03eb8957CD26B2bFA32dFe78160F6E26AF9b9CeE',
    },
    token: tokens.dogedrinks,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 82,
    lpSymbol: 'FDLS-BNB LP',
    lpAddresses: {
      56: '0x1b4387583f488832f2bc92e2e19218af1b1c074c',
    },
    isHidden: true,
    token: tokens.fdls,
    quoteToken: tokens.wbnb,
  },
  // EMPTY FARMS
  {
    pid: 39,
    isHidden: true,
    lpSymbol: 'USDT-BUSD LP',
    lpAddresses: {
      56: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },
]

export default farms
