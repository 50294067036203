import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT, DEFAULT_TOKEN_DECIMAL } from 'config'
import getGasPrice from 'utils/getGasPrice'

const options = {
  // gasLimit: DEFAULT_GAS_LIMIT,
}

export const stakeFarm = async (masterChefContract, pid, amount, referrer?) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()
  let tx
  if (referrer !== undefined) {
    tx = await masterChefContract["deposit(uint256,uint256,address)"](pid, value, referrer, { ...options, gasPrice })
  } else {
    tx = await masterChefContract["deposit(uint256,uint256)"](pid, value, { ...options, gasPrice })
  }
  const receipt = await tx.wait()
  return receipt.status
}

export const unstakeFarm = async (masterChefContract, pid, amount) => {
  const gasPrice = getGasPrice()
  const value = new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString()

  const tx = await masterChefContract.withdraw(pid, value, { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}

export const harvestFarm = async (masterChefContract, pid) => {
  const gasPrice = getGasPrice()
  const tx = await masterChefContract.withdraw(pid, '0', { ...options, gasPrice })
  const receipt = await tx.wait()
  return receipt.status
}
