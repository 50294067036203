import { Nft, NftSource, NftType } from './types'

export const IPFS_GATEWAY = 'https://cloudflare-ipfs.com'

export const nftSources: NftSource = {
  [NftType.OLIVE]: {
    address: {
      56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
      97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    },
    identifierKey: 'image',
  },
  [NftType.MIXIE]: {
    address: {
      56: '0xa251b5EAa9E67F2Bc8b33F33e20E91552Bf85566',
      97: '',
    },
    identifierKey: 'image',
  },
}

/**
 * NOTE: https://cloudflare-ipfs.com does not support video streaming so for the video URLS we need to use
 * https://gateway.pinata.cloud
 */

const Nfts: Nft[] = [
  {
    name: 'Common Olive',
    description: 'Don\'t call me a little olive until you\'ve picked me.',
    images: {
      lg: 'https://olive.cash/olive-apple.png',
      md: 'https://olive.cash/olive-apple.png',
      sm: 'https://olive.cash/olive-apple.png',
      ipfs: 'https://olive.cash/olive-apple.png',
    },
    video: {
      webm: 'https://olive-cash.github.io/nft/bsc/COMMON.mp4',
      mp4: 'https://olive-cash.github.io/nft/bsc/COMMON.mp4',
    },
    sortOrder: 999,
    identifier: 'bsc-common-1',
    type: NftType.OLIVE,
    variationId: 1,
  },
  {
    name: 'UnCommon Olive',
    description: 'Fortune and olives are alike: sometimes a man has an abundance and other times not any',
    images: {
      lg: 'https://olive.cash/olive-apple.png',
      md: 'https://olive.cash/olive-apple.png',
      sm: 'https://olive.cash/olive-apple.png',
      ipfs: 'https://olive.cash/olive-apple.png',
    },
    video: {
      webm: 'https://olive-cash.github.io/nft/bsc/UNCOMMON.mp4',
      mp4: 'https://olive-cash.github.io/nft/bsc/UNCOMMON.mp4',
    },
    sortOrder: 999,
    identifier: 'bsc-uncommon-1',
    type: NftType.OLIVE,
    variationId: 2,
  },
  {
    name: 'Rare Olive',
    description: ' You\'ll never be sated with bread and olives.',
    images: {
      lg: 'https://olive.cash/olive-apple.png',
      md: 'https://olive.cash/olive-apple.png',
      sm: 'https://olive.cash/olive-apple.png',
      ipfs: 'https://olive.cash/olive-apple.png',
    },
    video: {
      webm: 'https://olive-cash.github.io/nft/bsc/RARE.mp4',
      mp4: 'https://olive-cash.github.io/nft/bsc/RARE.mp4',
    },
    sortOrder: 999,
    identifier: 'bsc-rare-1',
    type: NftType.OLIVE,
    variationId: 3,
  },
  {
    name: 'Epic Olive',
    description: 'In the olive grove, a wise man at the feet and a wild man at the head.',
    images: {
      lg: 'https://olive.cash/olive-apple.png',
      md: 'https://olive.cash/olive-apple.png',
      sm: 'https://olive.cash/olive-apple.png',
      ipfs: 'https://olive.cash/olive-apple.png',
    },
    video: {
      webm: 'https://olive-cash.github.io/nft/bsc/EPIC.mp4',
      mp4: 'https://olive-cash.github.io/nft/bsc/EPIC.mp4',
    },
    sortOrder: 999,
    identifier: 'bsc-epic-1',
    type: NftType.OLIVE,
    variationId: 4,
  },
  {
    name: 'Legendary Olive',
    description: 'Kissing a girl for the first time is like getting the first olive from a jar: after the first one, they come rolling out.',
    images: {
      lg: 'https://olive.cash/olive-apple.png',
      md: 'https://olive.cash/olive-apple.png',
      sm: 'https://olive.cash/olive-apple.png',
      ipfs: 'https://olive.cash/olive-apple.png',
    },
    video: {
      webm: 'https://olive-cash.github.io/nft/bsc/LEGENDARY.mp4',
      mp4: 'https://olive-cash.github.io/nft/bsc/LEGENDARY.mp4',
    },
    sortOrder: 999,
    identifier: 'bsc-legendary-1',
    type: NftType.OLIVE,
    variationId: 5,
  },
  // {
  //   name: 'Olive Simple 2',
  //   description: 'Olive Description',
  //   images: {
  //     lg: 'https://olive.cash/olive-apple.png',
  //     md: 'https://olive.cash/olive-apple.png',
  //     sm: 'https://olive.cash/olive-apple.png',
  //     ipfs: 'https://olive.cash/olive-apple.png',
  //   },
  //   video: {
  //     webm: 'https://olive-cash.github.io/nft/bsc/simple.mp4',
  //     mp4: 'https://olive-cash.github.io/nft/bsc/simple.mp4',
  //   },
  //   sortOrder: 999,
  //   identifier: 'bsc-simple-0',
  //   type: NftType.OLIVE,
  //   variationId: 0,
  // },
]

export default Nfts
